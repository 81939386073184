import React, { useState } from 'react';
import BaseTable from './BaseTable';
import UserRow from './UserRow';
import { USER_COLUMNS } from './config/tableConfigs';
import I18n from '../../i18n';
import UserDetailsDialog from '../dialogs/UserDetailsDialog';

const DisplayUserTable = ({
  users,
  editingUserId,
  editingData,
  editingDataMap,
  setEditingData,
  newUser,
  newRowRef,
  handleUserEdit,
  handleUserDelete,
  handleUserSave,
  handleUserCancel,
  isLoading,
  loadingAction
}) => {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const headers = USER_COLUMNS.map(col => ({
    title: I18n.t(col.label),
    tooltip: I18n.t(col.tooltip),
    className: col.className
  }));

  const handleDetails = (user) => {
    setSelectedUser(user);
    setShowDetailsDialog(true);
  };

  const renderRow = (user, isEditing, actions) => (
    <UserRow
      user={user}
      isEditing={isEditing}
      editingData={isEditing ? editingDataMap[user.id] || editingData : null}
      setEditingData={setEditingData}
      actions={{
        ...actions,
        onEdit: () => handleUserEdit(user),
        onDelete: () => handleUserDelete(user.id),
        onSave: () => handleUserSave(editingDataMap[user.id] || editingData),
        onCancel: () => handleUserCancel(user.id),
        onDetails: () => handleDetails(user)
      }}
      isLoading={isLoading}
      loadingAction={loadingAction}
    />
  );

  return (
    <>
      <BaseTable
        items={users}
        headers={headers}
        editingId={editingUserId}
        renderRow={renderRow}
        newItem={newUser}
        newRowRef={newRowRef}
        isLoading={isLoading}
        loadingAction={loadingAction}
      />
      {showDetailsDialog && selectedUser && (
        <UserDetailsDialog
          user={selectedUser}
          onClose={() => setShowDetailsDialog(false)}
        />
      )}
    </>
  );
};

export default DisplayUserTable; 