import React, { useRef, useEffect } from 'react';
import '../css/Tooltip.css'; // Create and import a CSS file for custom styles

// Static variable to track active tooltip
let activeTooltipId = null;

const Tooltip = ({ content, children }) => {
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const tooltipId = useRef(Math.random().toString(36).substr(2, 9)); // Generate unique ID
  const sections = content.split('\n\n');  // Split on double newline to separate sections
  
  const updatePosition = () => {
    if (!triggerRef.current || !tooltipRef.current) return;

    // Hide any other active tooltip
    if (activeTooltipId && activeTooltipId !== tooltipId.current) {
      document.querySelectorAll('.tooltip-content').forEach(tooltip => {
        tooltip.style.visibility = 'hidden';
        tooltip.style.opacity = '0';
      });
    }
    
    activeTooltipId = tooltipId.current;
    tooltipRef.current.style.visibility = 'visible';
    tooltipRef.current.style.opacity = '1';

    const trigger = triggerRef.current.getBoundingClientRect();
    const tooltip = tooltipRef.current.getBoundingClientRect();

    // Check if there's room above
    const spaceAbove = trigger.top > tooltip.height + 10;
    
    // Position either above or below
    const top = spaceAbove 
      ? trigger.top - tooltip.height - 10  // Above
      : trigger.bottom + 10;               // Below

    // Center horizontally with the trigger
    const left = trigger.left + (trigger.width / 2) - (tooltip.width / 2);

    tooltipRef.current.style.top = `${top}px`;
    tooltipRef.current.style.left = `${left}px`;
  };

  const hideTooltip = () => {
    // Don't hide if text is being selected
    if (window.getSelection().toString()) {
      return;
    }

    if (tooltipRef.current) {
      activeTooltipId = null;
      tooltipRef.current.style.visibility = 'hidden';
      tooltipRef.current.style.opacity = '0';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

  return (
    <div 
      className="tooltip" 
      ref={triggerRef} 
      onMouseOver={updatePosition}
      onMouseOut={hideTooltip}
    >
      <span className="tooltip-trigger">{children}</span>
      <div 
        className="tooltip-content" 
        ref={tooltipRef}
        onMouseOver={updatePosition}
        onMouseOut={hideTooltip}
      >
        {sections.map((section, index) => {
          const [label, value] = section.split('\n');  // Split each section into label and value
          return (
            <div key={index} className="tooltip-section">
              <div className="tooltip-label">{label}</div>
              <div className="tooltip-value">{value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tooltip;
