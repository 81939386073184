export default {
  basic: {
    loading: 'Loading...',
    selectLanguage: 'Select Language',
  },
  navbar: {
    home: 'Home',
    members: 'Members Area',
    account: 'Account',
    practice: 'Practice System',
    performance: 'Performance',
    signin: 'Sign In',
    signup: 'Sign Up',
    signout: 'Sign Out',
    about: 'About Us',
    contact: 'Contact',
    admin: 'Admin',
  },
  home: {
    introTitle: 'Hello and welcome to testapp!',
    introSubTitle: 'A unique practice system that allows you to prepare for the exam with',
    introSubTitle2: 'winning success!',
    intro1: 'Hello',
    intro2: 'My name is Elior Shaked',
    intro3: 'I invite you to practice your coding skills in a',
    intro4: 'creative and independent way',
    intro5: 'Our system provides feedback on various tests for common exam questions, allowing you to assess the quality of your code while meeting the question requirements (recursion, runtime, etc...). Our journey is long, but we will make it together with a clear goal - to help you code optimally',
    intro6: 'and succeed in the exam',
    videoError: 'Error loading the video',
  },
  account: {
    title: 'Account Page',
    name: 'Name:',
    email: 'Email:',
    institute: 'Institute:',
    notPayingUser: 'You are not a paying user. Please contact the administrator to upgrade your account.',
  },
  contact: {
    questions: 'For any questions:',
    email: 'Email:',
  },
  about: {
    founder1: 'Elior: A good boy',
    founder2: 'Amit: A bad boy',
  },
  admin: {
    title: 'Admin Page - SECRET PAGE!',
    upload: 'Upload File',
    default: 'No file selected',
    verify: 'Verify and Upload CSV',
    alertNoFile: 'No file selected',
    alertSuccess: 'File processed successfully',
    alertPleaseUpload: 'Please upload a file with a .csv extension',
    questions: "Questions",
    tests: "Tests",
    addQuestion: "Add New Question",
    addTest: "Add New Test",
    testables: 'Testables',
    addTestable: 'Add Testable',
    users: 'Users',
    searchUsers: 'Search users by name, email, institute or department...',
    searchQuestions: 'Search questions by name...',

    dialogs: {
      duplicateQuestion: 'Duplicate Question',
      questionName: 'Question Name',
      associatedTestables: 'Associated Testables',
      editSolution: 'Edit Question Solution',
      editText: 'Edit Question Text',
      manageTags: 'Manage Tags',
      confirmDeleteTag: 'Are you sure you want to delete this tag? This cannot be undone.',
      editTag: 'Edit Tag',
      filterByTags: 'Filter by Tags',
      userDetails: 'User Details',
    },

    buttons: {
      edit: "Edit",
      delete: "Delete",
      save: "Save",
      cancel: "Cancel",
      duplicate: "Duplicate",
      addQuestion: 'Add Question',
      addTestable: 'Add Testable',
      addTest: 'Add Test',
      saving: 'Saving...',
      deleting: 'Deleting...',
      loading: 'Loading...',
      duplicating: 'Duplicating...',
      uploadConfirmedUsers: 'Upload Confirmed Users',
      simpleDuplicate: 'Simple Duplicate',
      fullDuplicate: 'Full Duplicate',
      editSolution: 'Edit Solution',
      editText: 'Edit Text',
      manageTags: 'Manage Tags',
      addNewTag: 'Add New Tag',
      addTag: 'Add',
      removeTag: 'Remove',
      deleteTag: 'Delete',
      close: 'Close',
      editTag: 'Edit',
      filterTags: 'Filter',
      clearFilter: 'Clear Filter',
      filter: 'Apply Filter',
      details: 'Details',
    },

    messages: {
      saveSuccess: "Successfully saved changes",
      saveError: "Error saving changes",
      deleteSuccess: "Successfully deleted",
      deleteError: "Error deleting item",
      invalidPythonDict: "Input must be a valid Python dictionary format (e.g. {'key': 'value'} or {})",
      duplicateQuestionVersion: "A question with this name and version already exists",
      duplicateTestName: "A test with this name already exists for this question",
      invalidVersion: "Version must be either a number, letters, or number followed by letters",
      invalidExpectedOutput: "Expected output must be a number, True, False, None, collection, or quoted string",
      deleteConfirm: 'Are you sure you want to delete this item?',
      duplicateTestables: 'Do you want to duplicate the associated testables and tests as well?',
      duplicateSuccess: 'Successfully duplicated item',
      duplicateError: 'Error duplicating item',
      fetchError: 'Error loading data',
      nameRequired: 'Name is required',
      duplicateQuestionName: 'A question with this name already exists',
      duplicateTestableName: 'A testable with this name already exists for this question',
      duplicateTestName: 'A test with this name already exists for this testable',
      nameTooLong: 'Name must be less than 100 characters',
      nameInvalidChars: 'Name can only contain letters, numbers, spaces, and underscores',
      duplicateNameSuffix: 'A copy of this item already exists with this name. Try a different suffix.',
      confirmDeleteTestable: 'Are you sure you want to delete this testable? This will also delete all associated tests.',
      confirmDeleteTest: 'Are you sure you want to delete this test?',
      emailRequired: 'Email is required',
      csvRequired: 'Please select a CSV file',
      csvError: 'Error processing CSV file',
      usersUpdated: 'Users updated successfully',
      testableNameExists: 'A testable with this name already exists. Please choose a different name.',
      duplicateTestableNameExists: 'Cannot duplicate: testable name "{name}" already exists. Please modify the name and try again.',
      noResults: 'No results found',
    },

    headers: {
      actions: 'Actions',
      id: 'ID',
      questionId: 'ID',
      testableId: 'ID',
      testId: 'ID',
      name: 'Name',
      testName: 'Test Name',
      questionName: 'Question Name',
      testableName: 'Testable Name',
      text: 'Text',
      difficulty: 'Difficulty',
      type: 'Type',
      className: 'Class Name',
      initInput: 'Init Input',
      timeComplexity: 'Time Complexity',
      recursive: 'Recursive',
      maxLoops: 'Max Loops',
      input: 'Input',
      expected: 'Expected Output',
      postprocess: 'Postprocess',
      allowEachExpected: 'OR',
      version: 'Version',
      userId: 'User ID',
      email: 'Email',
      isPaying: 'Paying User',
      expirationDate: 'Expiration Date',
      institute: 'Institute',
      department: 'Department',
      solution: 'Solution',
      tags: 'Tags',
      tooltips: {
        actions: "Available actions: Edit, Duplicate, Delete",
        id: "Automatically generated ID",
        questionId: "Automatically generated ID",
        testableId: "Automatically generated ID",
        testId: "Automatically generated ID",
        name: "Name of the item",
        testName: "Name of the test case",
        questionName: "Name of the question",
        testableName: "Name of the testable component",
        text: "Question description and requirements",
        type: "Type of testable (function/method)",
        className: "Name of the class if this is a method",
        initInput: "Input for class initialization",
        timeComplexity: "Expected time complexity",
        recursive: "Whether recursion is required",
        maxLoops: "Maximum allowed loops",
        input: "Test input value",
        expected: "Expected output value",
        allowEachExpected: 'Allow each expected value to be matched independently',
        postprocess: 'Python code to process the output before comparison',
        version: "Version format: number, letters, or number followed by letters",
        userId: 'Unique identifier for the user',
        email: 'User\'s email address',
        isPaying: 'Whether the user has paid for premium features',
        expirationDate: 'Expiration date of the user\'s account',
        institute: 'User\'s educational institute',
        department: 'User\'s department or major',
        solution: 'Python solution for this question',
        tags: 'Tags for the question',
      }
    },
    placeholders: {
      tagLabel: 'Enter tag label',
    },
    labels: {
      tagLabel: 'Label',
      tagColor: 'Color',
      useAndOperator: 'Match all selected tags (AND)',
      userStats: 'User Statistics',
      connections: 'Total Connections',
      uniqueQuestions: 'Unique Questions',
      totalSubmissions: 'Total Submissions',
      performanceHistory: 'Performance History',
    }
  },
  practice: {
    title: 'Python Code Checker',
    darkmode: 'Dark Mode',
    lightmode: 'Light Mode',
    font: 'Font Size',
    run: 'Run',
    submit: 'Submit',
    output: 'Output:',
    questionNotFound: 'Question {function_name} was not found.',
    didYouMean: 'Did you mean {match}?',
  },
  results: {
    functionName: 'Function Name',
    timeComplexity: 'Time Complexity',
    test: 'Test',
    error: 'Error',
    pass: 'Passed',
    fail: 'Failed',
    noTest: 'No Test',
    noLimit: 'No limit',
    input: 'Input',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
    syntaxError: 'Syntax Error',
    copyInput: 'Copy input',
    copyLastCode: 'Copy last submitted code',
    codeCopied: 'Code copied!',
  }, 
  performance: {
    title: ' Updated Submissions Table',
  },
  sign: {
    in: 'Sign In',
    up: 'Sign Up',
    email: 'Email',
    name: 'Name',
    institute: 'Select Institution',
    back: 'Back',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    resetPassword: 'Reset Password',
    codeWill: 'A reset code will be sent to your email:',
    sendCode: 'Send Reset Code',
    defaultCode: 'Enter confirmation code',
    defaultPassword: 'Enter new password',
    confirmAccount: 'Confirm Your Account',
    confirmationCode: 'Confirmation Code',
    confirmAccountNow: 'Confirm Account',
    resendCode: 'Resend Code',
    changeSuccess: 'Password has been reset successfully!',
    creatingAccount: 'Creating account...',
    authenticating: 'Authenticating...',
    gettingUserInfo: 'Getting user info...',
    verifying: 'Verifying...',
    institutions: {
      select: 'Select Institute',
      huji: 'Hebrew University',
      technion: 'Technion',
      tau: 'Tel Aviv University',
      bgu: 'Ben Gurion University',
      ariel: 'Ariel'
    }
  },
  errors: {
    fetchResults: 'Error fetching user results',
  }
};