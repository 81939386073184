import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import I18n from '../../i18n';
import '../../css/Dialog.css';
import EditTagDialog from './EditTagDialog';

const TagsDialog = ({ 
  question, 
  questionTags, 
  onClose, 
  onTagsUpdate 
}) => {
  const [allTags, setAllTags] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newTagLabel, setNewTagLabel] = useState('');
  const [newTagColor, setNewTagColor] = useState('#000000');
  const [isLoading, setIsLoading] = useState(false);
  const [editingTag, setEditingTag] = useState(null);

  useEffect(() => {
    fetchAllTags();
  }, []);

  const fetchAllTags = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/tags/get_all_tags`);
      setAllTags(response.data.tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleAddTagToQuestion = async (tag) => {
    try {
      await axios.post(
        `${config.API_URL}/tags/add_tag_to_question/${question.id}?label=${encodeURIComponent(tag.label)}`
      );
      onTagsUpdate();
      fetchAllTags();
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

  const handleRemoveTagFromQuestion = async (tag) => {
    try {
      await axios.delete(
        `${config.API_URL}/tags/remove_tag_from_question/${question.id}?label=${encodeURIComponent(tag.label)}`
      );
      onTagsUpdate();
      fetchAllTags();
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  const handleDeleteTag = async (tag) => {
    if (window.confirm(I18n.t('admin.dialogs.confirmDeleteTag'))) {
      try {
        await axios.delete(`${config.API_URL}/tags/delete_tag/${tag.label}`);
        fetchAllTags();
        onTagsUpdate();
      } catch (error) {
        console.error('Error deleting tag:', error);
      }
    }
  };

  const handleAddNewTag = async () => {
    try {
      await axios.post(
        `${config.API_URL}/tags/add_tag?label=${encodeURIComponent(newTagLabel)}&color=${encodeURIComponent(newTagColor)}`
      );
      setIsAddingNew(false);
      setNewTagLabel('');
      setNewTagColor('#000000');
      fetchAllTags();
    } catch (error) {
      console.error('Error adding new tag:', error);
    }
  };

  const currentTags = questionTags?.questions?.[question.id] || [];
  const currentTagLabels = new Set(currentTags.map(t => t.label));
  
  // Sort tags: current tags first, then remaining tags
  const sortedTags = [
    ...currentTags,
    ...allTags.filter(tag => !currentTagLabels.has(tag.label))
  ];

  const handleDialogClick = (e) => {
    e.stopPropagation();  // Stop event from reaching the table row
  };

  return (
    <div 
      className="dialog-overlay"
      onClick={handleDialogClick}  // Stop propagation on overlay
    >
      <div 
        className="dialog-content tags-dialog"
        onClick={handleDialogClick}  // Stop propagation on content
      >
        <h2>{I18n.t('admin.dialogs.manageTags')}</h2>
        
        <button 
          className="add-tag-button"
          onClick={() => setIsAddingNew(true)}
        >
          {I18n.t('admin.buttons.addNewTag')}
        </button>

        {isAddingNew && (
          <div className="new-tag-form">
            <input
              type="text"
              value={newTagLabel}
              onChange={(e) => setNewTagLabel(e.target.value)}
              placeholder={I18n.t('admin.placeholders.tagLabel')}
            />
            <input
              type="color"
              value={newTagColor}
              onChange={(e) => setNewTagColor(e.target.value)}
            />
            <button onClick={handleAddNewTag}>
              {I18n.t('admin.buttons.save')}
            </button>
            <button onClick={() => setIsAddingNew(false)}>
              {I18n.t('admin.buttons.cancel')}
            </button>
          </div>
        )}

        <div className="tags-list">
          {sortedTags.map((tag) => (
            <div key={tag.label} className="tag-item">
              <span 
                className="tag-label"
                style={{ backgroundColor: tag.color }}
              >
                {tag.label}
                {currentTagLabels.has(tag.label) && (
                  <span className="tag-check">✓</span>
                )}
              </span>
              <div className="tag-actions">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (currentTagLabels.has(tag.label)) {
                      handleRemoveTagFromQuestion(tag);
                    } else {
                      handleAddTagToQuestion(tag);
                    }
                  }}
                >
                  {currentTagLabels.has(tag.label) 
                    ? I18n.t('admin.buttons.removeTag')
                    : I18n.t('admin.buttons.addTag')}
                </button>
                <button 
                  className="edit-tag-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingTag(tag);
                  }}
                >
                  {I18n.t('admin.buttons.editTag')}
                </button>
                <button 
                  className="delete-tag-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteTag(tag);
                  }}
                >
                  {I18n.t('admin.buttons.deleteTag')}
                </button>
              </div>
            </div>
          ))}
        </div>

        {editingTag && (
          <EditTagDialog
            tag={editingTag}
            onClose={() => setEditingTag(null)}
            onUpdate={() => {
              fetchAllTags();
              onTagsUpdate();
            }}
          />
        )}

        <button 
          className="close-button"
          onClick={onClose}
        >
          {I18n.t('admin.buttons.close')}
        </button>
      </div>
    </div>
  );
};

export default TagsDialog; 