import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import I18n from '../../i18n';
import '../../css/Dialog.css';

const TagFilterDialog = ({ 
  onClose, 
  onFilter,
  selectedTags,
  isAndOperator
}) => {
  const [allTags, setAllTags] = useState([]);
  const [localSelectedTags, setLocalSelectedTags] = useState(new Set(selectedTags));
  const [useAndOperator, setUseAndOperator] = useState(isAndOperator);

  useEffect(() => {
    fetchAllTags();
  }, []);

  const fetchAllTags = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/tags/get_all_tags`);
      setAllTags(response.data.tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleTagClick = (tag) => {
    const newSelected = new Set(localSelectedTags);
    if (newSelected.has(tag.label)) {
      newSelected.delete(tag.label);
    } else {
      newSelected.add(tag.label);
    }
    setLocalSelectedTags(newSelected);
  };

  const handleFilter = () => {
    onFilter(Array.from(localSelectedTags), useAndOperator);
    onClose();
  };

  return (
    <div className="dialog-overlay" onClick={e => e.stopPropagation()}>
      <div className="dialog-content tag-filter-dialog">
        <h2>{I18n.t('admin.dialogs.filterByTags')}</h2>

        <div className="operator-selector">
          <label>
            <input
              type="checkbox"
              checked={useAndOperator}
              onChange={(e) => setUseAndOperator(e.target.checked)}
            />
            {I18n.t('admin.labels.useAndOperator')}
          </label>
        </div>

        <div className="tags-list">
          {allTags.map((tag) => (
            <div 
              key={tag.label} 
              className={`tag-item ${localSelectedTags.has(tag.label) ? 'selected' : ''}`}
              onClick={() => handleTagClick(tag)}
            >
              <span 
                className="tag-label"
                style={{ backgroundColor: tag.color }}
              >
                {tag.label}
                {localSelectedTags.has(tag.label) && (
                  <span className="tag-check">✓</span>
                )}
              </span>
            </div>
          ))}
        </div>

        <div className="dialog-buttons">
          <button 
            className="cancel-button"
            onClick={onClose}
          >
            {I18n.t('admin.buttons.cancel')}
          </button>
          <button 
            className="filter-button"
            onClick={handleFilter}
          >
            {I18n.t('admin.buttons.filter')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagFilterDialog; 