import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import '../css/CompilerPage.css'; // Create and import a CSS file for custom styles
import MonacoEditor from '@monaco-editor/react';
import ResultsTable from './ResultsTable';
import { sanitizePythonCode } from './securityFunctions';
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Configure PDF worker with a different CDN
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

const CompilerPage = ({userInfo}) => {
    const navigate = useNavigate();
    const { language } = useContext(LanguageContext);
    const [code, setCode] = useState('');
    const [output, setOutput] = useState('');
    const [results, setResults] = useState('');
    const [uselessResults, setUselessResults] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [fontSize, setFontSize] = useState(14);
    const [isOutputVisible, setIsOutputVisible] = useState(false);
    const [theme, setTheme] = useState('vs-dark');
    const apiUrl = config.API_URL;
    
    const LOCAL_STORAGE_KEY = 'compiler_code';

    const [outputTxt, setOutputTxt] = useState(I18n.t('practice.output'));
    const [darkmode, setDarkmode] = useState(I18n.t('practice.darkmode'));
    const [lightmode, setLightmode] = useState(I18n.t('practice.lightmode'));
    const [font, setFont] = useState(I18n.t('practice.font'));
    const [title, setTitle] = useState(I18n.t('practice.title'));
    const [submit, setSubmit] = useState(I18n.t('practice.submit'));
    const [run, setRun] = useState(I18n.t('practice.run'));
    const [questionNotFound, setQuestionNotFound] = useState(I18n.t('practice.questionNotFound'));
    const [didYouMean, setDidYouMean] = useState(I18n.t('practice.didYouMean'));
    const [loading, setLoading] = useState(I18n.t('basic.loading'));
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const defaultZoom = 1.4;
    const [scale, setScale] = useState(defaultZoom);
    const [pageInputValue, setPageInputValue] = useState(1);
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        setOutputTxt(I18n.t('practice.output'));
        setDarkmode(I18n.t('practice.darkmode'));
        setLightmode(I18n.t('practice.lightmode'));
        setFont(I18n.t('practice.font'));
        setTitle(I18n.t('practice.title'));
        setSubmit(I18n.t('practice.submit'));
        setRun(I18n.t('practice.run'));
        setQuestionNotFound(I18n.t('practice.questionNotFound'));
        setDidYouMean(I18n.t('practice.didYouMean'));
        setLoading(I18n.t('basic.loading'));
    }, [language]);

    useEffect(() => {
        const savedCode = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (savedCode) {
            setCode(savedCode);
        }
    }, []);

    useEffect(() => {
        if (userInfo !== null) {
            const isPayingUser = userInfo && userInfo.expiration_date ? new Date(userInfo.expiration_date) > new Date() : false;
            if (!isPayingUser) {
              alert(I18n.t('account.notPayingUser'));
                navigate('/');
            }

        }
    }, [userInfo, navigate]);

    useEffect(() => {
        console.log('userInfo: ', userInfo);
        const bucket_name = "testapp-pdfs";
        const year = "2025";
        if (userInfo !== null) {
            setPdfUrl(`https://${bucket_name}.s3.eu-north-1.amazonaws.com/${userInfo.institute}_${userInfo.department}_${year}.pdf`)
            if (userInfo.institute === null || userInfo.department === null || userInfo.institute === "" || userInfo.department === "") {
                setPdfUrl(null);
            }
        }
    }, [userInfo]);

    useEffect(() => {
        setPageInputValue(pageNumber);
    }, [pageNumber]);

    if (userInfo === null) {
        return <div>{loading}</div>;
    }

    const handleEditorChange = (value, event) => {
        setCode(value);
        localStorage.setItem(LOCAL_STORAGE_KEY, value);
    };

    const handleFontSizeChange = (event) => {
        setFontSize(parseInt(event.target.value, 10));
    };
    
    const handleCloseOutput = () => {
        setIsOutputVisible(false);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.py')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setCode(e.target.result);
            };
            reader.readAsText(file);
        } else {
            alert('Please choose a .py file.');
        }
    };

    const handleRun = async () => {
        console.log("Submitted code:", code);
        const sanitizedCode = sanitizePythonCode(code);
        
        if (typeof sanitizedCode === 'string' && sanitizedCode.startsWith('Code must be')) {
            alert(sanitizedCode);
            return;
        }
        setIsRunning(true);
        axios.post(`${apiUrl}/code/run`, { user_uuid: userInfo.uuid, code: sanitizedCode })
            .then(response => {
                console.log('Code output:', response.data);
                setOutput(response.data.output);
                setIsOutputVisible(true);
            })
            .catch(error => {
                console.error('Error evaluating code:', error);
            }).finally(() => {
                setIsRunning(false);
            });
    };

    const handleSubmit = () => {
        console.log("Submitted code:", code);
        const sanitizedCode = sanitizePythonCode(code);
        
        if (typeof sanitizedCode === 'string' && sanitizedCode.startsWith('Code must be')) {
            alert(sanitizedCode);
            return;
        }
        setIsSubmitting(true);
        axios.post(`${apiUrl}/code/submit`, {user_uuid: userInfo.uuid, code: sanitizedCode})
        .then(response => {
            console.log('Test results:', response.data);
            // Pass the error response directly
            if (response.data['<error>']) {
                setResults(response.data);  // Don't restructure it
                setUselessResults([]);
            } else {
                // Handle normal case
                console.log('Test results_per_function:', response.data.results_per_function);
                console.log('Test useless_functions:', response.data.useless_functions);
                setResults(response.data.results_per_function);
                setUselessResults(response.data.useless_functions);
            }
        })
        .catch(error => {
            console.error('Error testing code:', error);
            setResults(null);
            setUselessResults([]);
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    const renderOutputBox = () => {
        console.log('output: ', output)
        if (!output) return null;

        if (!isOutputVisible) return null;
    
        return (
            <div className="output-box-container">
                <div className={`output-box-title ${document.body.dir}`}>
                    <h3>{outputTxt}</h3>
                    <button className="close-output-btn" onClick={handleCloseOutput}>✖</button>
                </div>
                <div className="output-box-code">
                    <pre>{output}</pre>
                </div>
            </div>
        );
    };
    
    const renderTranslatedMessage = (message, variables) => {
        const regex = /{(\w+)}/g;
        const parts = [];
        let lastIndex = 0;
    
        let match;
        while ((match = regex.exec(message)) !== null) {
            const index = match.index;
            const variableName = match[1];
    
            if (index > lastIndex) {
                parts.push(message.substring(lastIndex, index));
            }
    
            if (variables[variableName]) {
                parts.push(
                    <strong key={index}>{variables[variableName]}</strong>
                );
            } else {
                parts.push('');
            }
    
            lastIndex = regex.lastIndex;
        }
    
        if (lastIndex < message.length) {
            parts.push(message.substring(lastIndex));
        }
    
        return parts;
    };

    const renderResultsTable = () => {
        console.log('results: ', results);
        return (
            <div className="results-table-container">
                <ResultsTable results={results} />
                {uselessResults && uselessResults.length > 0 && (
                    <div className="useless-functions-container">
                        {uselessResults.map((item, index) => (
                            <p className="useless-functions-text" key={index}>
                                {item.match === null ? (
                                    renderTranslatedMessage(questionNotFound, {
                                        function_name: item.function_name,
                                    })
                                ) : (
                                    <>
                                        {renderTranslatedMessage(questionNotFound, {
                                            function_name: item.function_name,
                                        })}{' '}
                                        {renderTranslatedMessage(didYouMean, {
                                            match: item.match,
                                        })}
                                    </>
                                )}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'vs-dark' ? 'vs' : 'vs-dark'));
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const zoomIn = () => {
        setScale(prevScale => Math.min(prevScale + 0.2, 2.0)); // Max zoom 200%
    };

    const zoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.2, 0.5)); // Min zoom 50%
    };

    const handlePageInputChange = (e) => {
        const value = e.target.value;
        setPageInputValue(value);
    };

    const handlePageInputSubmit = (e) => {
        if (e.key === 'Enter') {
            const newPage = parseInt(pageInputValue);
            if (!isNaN(newPage) && newPage >= 1 && newPage <= numPages) {
                setPageNumber(newPage);
            } else {
                setPageInputValue(pageNumber); // Reset to current page if invalid
            }
        }
    };

    return (
        <div className="compiler-page">
            <div className="editor-container">
                <h2 className="editor-heading">{title}</h2>
                <div className="control-panel">
                    <div className="control-panel-left">
                        <button 
                            className={`theme-toggle-button ${theme === 'vs-dark' ? '' : 'light'}`}
                            onClick={toggleTheme}
                        >
                            {theme === 'vs-dark' ? darkmode : lightmode}
                        </button>
                        <div>
                            <label htmlFor="fontSizeSelect">{font}</label>
                            <select 
                                id="fontSizeSelect" 
                                value={fontSize} 
                                onChange={handleFontSizeChange} 
                                className="font-size-select"
                            >
                                {[8, 10, 12, 14, 16, 18, 20, 22, 24].map(size => (
                                    <option key={size} value={size}>{size}px</option>
                                ))}
                            </select>
                        </div>
                        <button
                            className="file-upload-button"
                            onClick={() => document.getElementById('fileUpload').click()}
                        >
                            <i className="fas fa-upload"></i>
                        </button>
                    </div>
                    <div className="control-panel-right">
                        {numPages && (
                            <div className="pdf-controls">
                                <button onClick={() => setPageNumber(1)} disabled={pageNumber <= 1}>
                                    «
                                </button>
                                <button onClick={() => setPageNumber(prev => Math.max(1, prev - 1))} disabled={pageNumber <= 1}>
                                    ‹
                                </button>
                                <div className="page-input-container">
                                    <input
                                        type="text"
                                        value={pageInputValue}
                                        onChange={handlePageInputChange}
                                        onKeyDown={handlePageInputSubmit}
                                        className="page-input"
                                        size="3"
                                    />
                                    <span> / {numPages}</span>
                                </div>
                                <button onClick={() => setPageNumber(prev => Math.min(numPages, prev + 1))} disabled={pageNumber >= numPages}>
                                    ›
                                </button>
                                <div className="pdf-zoom-controls">
                                    <button onClick={zoomOut} disabled={scale <= 0.5}>−</button>
                                    <span>{Math.round(scale * 100) - (defaultZoom*100 - 100)}%</span>
                                    <button onClick={zoomIn} disabled={scale >= 2.0}>+</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="editor-pdf-layout">
                    <div className="monaco-container">
                        <div className="window-dots">
                            <span className="red-dot"></span>
                            <span className="yellow-dot"></span>
                            <span className="green-dot"></span>
                        </div>
                        <MonacoEditor
                            height="400px"
                            width="100%"
                            defaultLanguage="python"
                            defaultValue={"# Write your Python code here"}
                            value={code}
                            onChange={handleEditorChange}
                            theme={theme}
                            options={{
                                selectOnLineNumbers: true,
                                roundedSelection: false,
                                readOnly: false,
                                cursorStyle: 'line',
                                automaticLayout: true,
                                fontSize: fontSize,
                            }}
                        />
                    </div>
                    {pdfUrl && (
                        <div className="pdf-section">
                            <div className="pdf-container">
                                <div className="window-dots">
                                    <span className="red-dot"></span>
                                    <span className="yellow-dot"></span>
                                    <span className="green-dot"></span>
                                </div>
                                <Document
                                    file={pdfUrl}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={(error) => console.error('PDF Load Error:', error)}
                                    loading={<div className="loading-spinner"></div>}
                                >
                                    <Page 
                                        pageNumber={pageNumber}
                                        scale={scale}
                                        width={400}
                                    />
                                </Document>
                            </div>
                        </div>
                    )}
                </div>
                <div className="button-group">
                    <button 
                        onClick={handleRun} 
                        className="run-button"
                        disabled={isRunning}
                    >
                        {isRunning ? (
                            <span className="loading-spinner"></span>
                        ) : (
                            run
                        )}
                    </button>
                    
                    <button 
                        onClick={handleSubmit} 
                        className="submit-button"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <span className="loading-spinner"></span>
                        ) : (
                            submit
                        )}
                    </button>
                </div>
                {renderOutputBox()}
                {renderResultsTable()}
            </div>
        </div>
    );
};

export default CompilerPage;