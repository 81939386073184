import React, { useState, useEffect } from 'react';
import I18n from '../../i18n';
import axios from 'axios';
import config from '../../config';
import ResultsTable from '../ResultsTable';

const UserDetailsDialog = ({ user, onClose }) => {
  const [results, setResults] = useState(null);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [isLoadingResults, setIsLoadingResults] = useState(true);
  const [isLoadingSubmissions, setIsLoadingSubmissions] = useState(true);
  const [resultsError, setResultsError] = useState(null);
  const [submissionsError, setSubmissionsError] = useState(null);

  useEffect(() => {
    // Fetch results
    const fetchResults = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/results/get_results/${user.uuid}`);
        setResults(response.data.results_per_function);
      } catch (err) {
        console.error('Error fetching results:', err);
        setResultsError(I18n.t('errors.fetchResults'));
      } finally {
        setIsLoadingResults(false);
      }
    };

    // Fetch submission count
    const fetchSubmissionCount = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/users/submission_count/${user.uuid}`);
        setSubmissionCount(response.data.submission_count);
      } catch (err) {
        console.error('Error fetching submission count:', err);
        setSubmissionsError(I18n.t('errors.fetchResults'));
      } finally {
        setIsLoadingSubmissions(false);
      }
    };

    fetchResults();
    fetchSubmissionCount();
  }, [user.uuid]);

  // Calculate unique questions from results_per_function
  const uniqueQuestions = results ? Object.keys(results).length : 0;

  return (
    <div className="dialog-overlay" onClick={onClose}>
      <div className="dialog-content" onClick={e => e.stopPropagation()}>
        <div className="dialog-header">
          <h2>{I18n.t('admin.dialogs.userDetails')}</h2>
          <button className="dialog-close" onClick={onClose}>×</button>
        </div>
        <div className="user-details">
          <div className="stats-section">
            <h3>{I18n.t('admin.labels.userStats')}</h3>
            <div className="stats-grid">
              <div className="stat-item">
                <span className="stat-label">{I18n.t('admin.labels.connections')}</span>
                <span className="stat-value">-</span>
              </div>
              <div className="stat-item">
                <span className="stat-label">{I18n.t('admin.labels.uniqueQuestions')}</span>
                <span className="stat-value">{uniqueQuestions}</span>
              </div>
              <div className="stat-item">
                <span className="stat-label">{I18n.t('admin.labels.totalSubmissions')}</span>
                <span className="stat-value">
                  {isLoadingSubmissions ? I18n.t('basic.loading') : 
                   submissionsError ? '-' : submissionCount}
                </span>
              </div>
            </div>
          </div>

          <div className="performance-section">
            <h3>{I18n.t('admin.labels.performanceHistory')}</h3>
            {isLoadingResults ? (
              <div className="loading">{I18n.t('basic.loading')}</div>
            ) : resultsError ? (
              <div className="error">{resultsError}</div>
            ) : !results ? (
              <div className="no-data">{I18n.t('admin.messages.noResults')}</div>
            ) : (
              <ResultsTable results={results} userUuid={user.uuid} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsDialog; 