import React, { useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import I18n from '../../i18n';
import '../../css/Dialog.css';

const SolutionDialog = ({ 
  question,
  onSave,
  onCancel,
  initialSolution = '',
  isLoading,
  title = I18n.t('admin.dialogs.editSolution')
}) => {
  const [solution, setSolution] = useState(initialSolution);

  const handleSave = () => {
    onSave(solution);
  };

  if (isLoading) {
    return (
      <div className="dialog-overlay">
        <div className="dialog-content solution-dialog">
          <div className="loading-spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="dialog-overlay">
      <div className="dialog-content solution-dialog">
        <div className="dialog-header">
          <h2>{title}</h2>
          <div className="dialog-buttons">
            <button 
              className="cancel-button" 
              onClick={onCancel}
            >
              {I18n.t('admin.buttons.cancel')}
            </button>
            <button 
              className="save-button" 
              onClick={handleSave}
            >
              {I18n.t('admin.buttons.save')}
            </button>
          </div>
        </div>
        <div className="editor-container">
          <MonacoEditor
            height="200px"
            language="python"
            theme="light"
            value={solution}
            onChange={setSolution}
            options={{
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              fontSize: 14,
              lineNumbers: 'on',
              automaticLayout: true
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SolutionDialog; 