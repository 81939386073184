export default {
  basic: {
    loading: 'טוען...',
    selectLanguage: 'בחר שפה',
  },
  navbar: {
    home: 'דף הבית',
    members: 'אזור אישי',
    account: 'חשבון',
    practice: 'מערכת תרגול',
    performance: 'ביצועים',
    signin: 'התחברות',
    signup: 'הרשמה',
    signout: 'התנתקות',
    about: 'אודות',
    contact: 'יצירת קשר',
    admin: 'ניהול',
  },
  home: {
    introTitle: 'שלום וברוכים הבאים לtestapp!',
    introSubTitle: 'מערכת תרגול ייחודית המאפשרת לך להתכונן למבחן עם',
    introSubTitle2: 'הצלחה מובטחת!',
    intro1: 'שלום',
    intro2: 'שמי אליאור שקד',
    intro3: 'אני מזמין אתכם לתרגל את כישורי התכנות שלכם בצורה',
    intro4: 'יצירתית ועצמאית',
    intro5: 'המערכת שלנו מספקת משוב על מבחנים שונים לשאלות נפוצות במבחן, ומאפשרת לך להעריך את איכות הקוד שלך תוך עמידה בדרישות השאלה (רקורסיה, זמן ריצה וכו׳). הדרך שלנו ארוכה, אבל נעשה אותה יחד עם מטרה ברורה - לעזור לך לכתוב קוד אופטימלי',
    intro6: 'ולהצליח במבחן',
    videoError: 'שגיאה בטעינת הוידאו',
  },
  account: {
    title: 'דף החשבון',
    name: 'שם:',
    email: 'דוא"ל:',
    institute: 'מוסד אקדמי:',
    notPayingUser: 'אינך משתמש בתשלום. אנא צור קשר עם המנהל לשדרוג החשבון שלך.',
  },
  contact: {
    questions: 'לשאלות:',
    email: 'דוא"ל:',
  },
  about: {
    founder1: 'אליאור: ילד טוב',
    founder2: 'עמית: ילד רע',
  },
  admin: {
    title: 'דף ניהול - דף סודי!',
    upload: 'העלה קובץ',
    default: 'לא נבחר קובץ',
    verify: 'אמת והעלה CSV',
    alertNoFile: 'לא נבחר קובץ',
    alertSuccess: 'הקובץ עובד בהצלחה',
    alertPleaseUpload: 'אנא העלה קובץ עם סיומת .csv',
    questions: "שאלות",
    tests: "בדיקות",
    addQuestion: "הוסף שאלה חדשה",
    addTest: "הוסף בדיקה חדשה",
    testables: 'רכיבים לבדיקה',
    addTestable: 'הוסף רכיב לבדיקה',
    users: 'משתמשים',
    searchUsers: 'חפש משתמשים לפי שם, דוא"ל, מוסד או מחלקה...',
    searchQuestions: 'חפש שאלות לפי שם...',

    buttons: {
      edit: "ערוך",
      delete: "מחק",
      save: "שמור",
      cancel: "בטל",
      duplicate: "שכפל",
      addQuestion: 'הוסף שאלה',
      addTestable: 'הוסף רכיב לבדיקה',
      addTest: 'הוסף בדיקה',
      saving: 'שומר...',
      deleting: 'מוחק...',
      loading: 'טוען...',
      duplicating: 'משכפל...',
      uploadConfirmedUsers: 'העלאת משתמשים מאושרים',
      simpleDuplicate: 'שכפול פשוט',
      fullDuplicate: 'שכפול מלא',
      editText: 'ערוך טקסט',
      editSolution: 'ערוך פתרון',
      manageTags: 'ניהול תוויות',
      addNewTag: 'הוסף תווית חדשה',
      addTag: 'הוסף',
      removeTag: 'הסר',
      deleteTag: 'מחק',
      close: 'סגור',
      editTag: 'ערוך',
      filterTags: 'סנן',
      clearFilter: 'נקה סינון',
      filter: 'החל סינון',
      details: 'פרטים',
    },

    messages: {
      saveSuccess: "השינויים נשמרו בהצלחה",
      saveError: "שגיאה בשמירת השינויים",
      deleteSuccess: "נמחק בהצלחה",
      deleteError: "שגיאה במחיקת הפריט",
      invalidPythonDict: "הקלט חייב להיות בפורמט מילון פייתון תקין (לדוגמה: {'key': 'value'} או {})",
      duplicateQuestionVersion: "קיימת כבר שאלה עם שם וגרסה זהים",
      duplicateTestName: "קיימת כבר בדיקה בשם זה עבור שאלה זו",
      invalidVersion: "הגרסה חייבת להיות מספר, אותיות, או מספר ואחריו אותיות",
      invalidExpectedOutput: "הפלט הצפוי חייב להיות מספר, True, False, None, אוסף, או מחרוזת בגרשיים",
      deleteConfirm: 'האם אתה בטוח שברצונך למחוק פריט זה?',
      duplicateTestables: 'האם ברצונך לשכפל גם את הרכיבים והבדיקות המשויכים?',
      duplicateSuccess: 'הפריט שוכפל בהצלחה',
      duplicateError: 'שגיאה בשכפול הפריט',
      fetchError: 'שגיאה בטעינת הנתונים',
      nameRequired: 'נדרש שם',
      duplicateQuestionName: 'קיימת כבר שאלה בשם זה',
      duplicateTestableName: 'קיים כבר רכיב בשם זה עבור שאלה זו',
      duplicateTestName: 'קיימת כבר בדיקה בשם זה עבור רכיב זה',
      nameTooLong: 'השם חייב להיות קצר מ-100 תווים',
      nameInvalidChars: 'השם יכול להכיל רק אותיות, מספרים, רווחים וקווים תחתונים',
      duplicateNameSuffix: 'כבר קיים עותק של פריט זה עם שם זה. נסה סיומת אחרת.',
      confirmDeleteTestable: 'האם אתה בטוח שברצונך למחוק רכיב זה? כל הבדיקות המשויכות יימחקו גם כן.',
      confirmDeleteTest: 'האם אתה בטוח שברצונך למחוק בדיקה זו?',
      emailRequired: 'נדרש דוא"ל',
      csvRequired: 'אנא בחר קובץ CSV',
      csvError: 'שגיאה בעיבוד קובץ CSV',
      usersUpdated: 'המשתמשים עודכנו בהצלחה',
      testableNameExists: 'קיים כבר רכיב לבדיקה בשם זה. אנא בחר שם אחר.',
      duplicateTestableNameExists: 'לא ניתן לשכפל: קיים כבר רכיב לבדיקה בשם "{name}". אנא שנה את השם ונסה שוב.',
      noResults: 'לא נמצאו תוצאות',
    },

    headers: {
      actions: 'פעולות',
      id: 'מזהה',
      questionId: 'מזהה',
      testableId: 'מזהה',
      testId: 'מזהה',
      name: 'שם',
      testName: 'שם הבדיקה',
      questionName: 'שם השאלה',
      testableName: 'שם הרכיב',
      text: 'טקסט',
      difficulty: 'רמת קושי',
      type: 'סוג',
      className: 'שם המחלקה',
      initInput: 'קלט התחלתי',
      timeComplexity: 'סיבוכיות זמן',
      recursive: 'רקורסיבי',
      maxLoops: 'מקסימום לולאות',
      input: 'קלט',
      expected: 'פלט צפוי',
      postprocess: 'עיבוד לאחר',
      allowEachExpected: 'או',
      version: 'גרסה',
      userId: 'מזהה משתמש',
      email: 'דוא"ל',
      isPaying: 'משתמש בתשלום',
      expirationDate: 'תאריך תפוגה',
      institute: 'מוסד',
      department: 'מחלקה',
      solution: 'פתרון',
      tags: 'תוויות',
      tooltips: {
        actions: "פעולות אפשריות: עריכה, שכפול, מחיקה",
        id: "מזהה שנוצר אוטומטית",
        questionId: "מזהה שנוצר אוטומטית",
        testableId: "מזהה שנוצר אוטומטית",
        testId: "מזהה שנוצר אוטומטית",
        name: "שם הפריט",
        testName: "שם מקרה הבדיקה",
        questionName: "שם השאלה",
        testableName: "שם הרכיב הנבדק",
        text: "תיאור השאלה ודרישותיה",
        type: "סוג הרכיב (פונקציה/מתודה)",
        className: "שם המחלקה אם זו מתודה",
        initInput: "קלט לאתחול המחלקה",
        timeComplexity: "סיבוכיות זמן צפויה",
        recursive: "האם נדרשת רקורסיה",
        maxLoops: "מספר לולאות מקסימלי מותר",
        input: "ערך קלט לבדיקה",
        expected: "ערך פלט צפוי",
        allowEachExpected: 'אפשר התאמה עצמאית של כל ערך צפוי',
        postprocess: 'קוד פייתון לעיבוד הפלט לפני השוואה',
        version: "פורמט גרסה: מספר, אותיות, או מספר ואחריו אותיות",
        userId: 'מזהה ייחודי למשתמש',
        email: 'כתובת הדוא"ל של המשתמש',
        isPaying: 'האם המשתמש שילם עבור תכונות פרימיום',
        expirationDate: 'תאריך תפוגה של חשבון המשתמש',
        institute: 'מוסד לימודים של המשתמש',
        department: 'מחלקה או התמחות של המשתמש',
        solution: 'פתרון בפייתון לשאלה זו',
        tags: 'תוויות של השאלה',
      }
    },
    dialogs: {
      duplicateQuestion: 'שכפול שאלה',
      questionName: 'שם השאלה',
      associatedTestables: 'רכיבים לבדיקה משויכים',
      editText: 'ערוך טקסט השאלה',
      editSolution: 'ערוך פתרון',
      manageTags: 'ניהול תוויות',
      confirmDeleteTag: 'האם אתה בטוח שברצונך למחוק תווית זו? פעולה זו אינה הפיכה.',
      editTag: 'ערוך תווית',
      filterByTags: 'סינון לפי תוויות',
      userDetails: 'פרטי משתמש',
    },
    placeholders: {
      tagLabel: 'הכנס שם תווית',
    },
    labels: {
      tagLabel: 'תווית',
      tagColor: 'צבע',
      useAndOperator: 'התאם לכל התוויות שנבחרו (וגם)',
      userStats: 'סטטיסטיקת משתמש',
      connections: 'סה"כ התחברויות',
      uniqueQuestions: 'שאלות ייחודיות',
      totalSubmissions: 'סה"כ הגשות',
      performanceHistory: 'היסטוריית ביצועים',
    }
  },
  practice: {
    title: 'בודק קוד פייתון',
    darkmode: 'מצב כהה',
    lightmode: 'מצב בהיר',
    font: 'גודל גופן',
    run: 'הרץ',
    submit: 'הגש',
    output: 'פלט:',
    questionNotFound: 'השאלה {function_name} לא נמצאה.',
    didYouMean: 'האם התכוונת ל {match}?',
  },
  results: {
    functionName: 'שם הפונקציה',
    timeComplexity: 'סיבוכיות זמן',
    test: 'בדיקה',
    error: 'שגיאה',
    pass: 'עבר',
    fail: 'נכשל',
    noTest: 'אין בדיקה',
    noLimit: 'ללא הגבלה',
    input: 'קלט',
    expected: 'פלט צפוי',
    actual: 'פלט בפועל',
    failedRecursion: 'הקוד צריך להיות רקורסיבי',
    failedLoops: 'השאלה מאפשרת {n_loops_allowed} לולאות, אך נמצאו {actual_loops}',
    failedStructure: 'מבנה לא תקין',
    syntaxError: 'שגיאת תחביר',
    copyInput: 'העתק קלט',
    copyLastCode: 'העתק קוד אחרון שהוגש',
    codeCopied: 'הקוד הועתק!',
  },
  performance: {
    title: 'טבלת הגשות מעודכנת',
  },
  sign: {
    in: 'התחברות',
    up: 'הרשמה',
    email: 'דוא"ל',
    name: 'שם',
    institute: 'מוסד אקדמי',
    back: 'חזרה',
    password: 'סיסמה',
    forgotPassword: 'שכחת סיסמה?',
    resetPassword: 'איפוס סיסמה',
    codeWill: 'קוד איפוס יישלח לדוא"ל שלך:',
    sendCode: 'שליחת קוד איפוס',
    defaultCode: 'הכנסת קוד אימות',
    defaultPassword: 'הכנסת סיסמה חדשה',
    confirmAccount: 'אימות חשבון',
    confirmationCode: 'קוד אימות',
    confirmAccountNow: 'אימות חשבון',
    resendCode: 'שלח קוד מחדש',
    changeSuccess: 'הסיסמה אופסה בהצלחה!',
    creatingAccount: 'יצירת חשבון...',
    authenticating: 'אימות...',
    gettingUserInfo: 'מידע משתמש...',
    verifying: 'אימות...',
    institutions: {
      select: 'בחר מוסד אקדמי',
      huji: 'האוניברסיטה העברית',
      technion: 'הטכניון',
      tau: 'אוניברסיטת תל אביב',
      bgu: 'אוניברסיטת בן גוריון',
      ariel: 'אריאל'
    }
  },
  signup: {
    institute: 'בחר מוסד אקדמי'
  },
  errors: {
    fetchResults: 'שגיאה בטעינת תוצאות המשתמש',
  }
};
