import React, { useContext, useEffect, useState } from 'react';
import Tooltip from './Tooltip'; // Assuming Tooltip is a separate component
import '../css/ResultsTable.css'; // Create and import a CSS file for custom styles
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext'; // Import LanguageContext
import axios from 'axios';
import config from '../config';

const ResultsTable = ({ results, userUuid }) => {
  const { language } = useContext(LanguageContext); // Get the language from context
  const [copySuccess, setCopySuccess] = useState('');

  // State variables for translated text
  const [functionNameText, setFunctionNameText] = useState(I18n.t('results.functionName'));
  const [timeComplexityText, setTimeComplexityText] = useState(I18n.t('results.timeComplexity'));
  const [testText, setTestText] = useState(I18n.t('results.test'));
  const [errorText, setErrorText] = useState(I18n.t('results.error'));
  const [failText, setFailText] = useState(I18n.t('results.fail'));
  const [passText, setPassText] = useState(I18n.t('results.pass'));
  const [noTestText, setNoTestText] = useState(I18n.t('results.noTest'));
  const [noLimitText, setNoLimitText] = useState(I18n.t('results.noLimit'));
  const [expectedText, setExpectedText] = useState(I18n.t('results.expected'));
  const [actualText, setActualText] = useState(I18n.t('results.actual'));
  const [failedRecursionText, setFailedRecursionText] = useState(I18n.t('results.failedRecursion'));
  const [failedLoopsText, setFailedLoopsText] = useState(I18n.t('results.failedLoops'));
  const [failedStructureText, setFailedStructureText] = useState(I18n.t('results.failedStructure'));
  const [inputText, setInputText] = useState(I18n.t('results.input'));
  const [expectedOutputText, setExpectedOutputText] = useState(I18n.t('results.expected'));
  const [actualOutputText, setActualOutputText] = useState(I18n.t('results.actual'));
  const [syntaxErrorText, setSyntaxErrorText] = useState(I18n.t('results.syntaxError'));

  // Update translations when the language changes
  useEffect(() => {
    setFunctionNameText(I18n.t('results.functionName'));
    setTimeComplexityText(I18n.t('results.timeComplexity'));
    setTestText(I18n.t('results.test'));
    setErrorText(I18n.t('results.error'));
    setFailText(I18n.t('results.fail'));
    setPassText(I18n.t('results.pass'));
    setNoTestText(I18n.t('results.noTest'));
    setNoLimitText(I18n.t('results.noLimit'));
    setExpectedText(I18n.t('results.expected'));
    setActualText(I18n.t('results.actual'));
    setFailedRecursionText(I18n.t('results.failedRecursion'));
    setFailedLoopsText(I18n.t('results.failedLoops'));
    setFailedStructureText(I18n.t('results.failedStructure'));
    setInputText(I18n.t('results.input'));
    setExpectedOutputText(I18n.t('results.expected'));
    setActualOutputText(I18n.t('results.actual'));
    setSyntaxErrorText(I18n.t('results.syntaxError'));
  }, [language]);

  const copyCode = async (functionName) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/results/last_submission_code/${userUuid}/${functionName}`
      );
      
      // Extract the code field from the response
      const code = response.data.code;
      await navigator.clipboard.writeText(code);
      setCopySuccess(functionName);
      
      // Clear success message after 2 seconds
      setTimeout(() => {
        setCopySuccess('');
      }, 2000);
    } catch (err) {
      console.error('Error copying code:', err);
    }
  };

  if (!results) return null;

  // Before checking for syntax_error property, check if it's an error response
  if (results['<error>']) {
    return (
      <div className="syntax-error-container">
        <h3>{syntaxErrorText}</h3>
        <pre className="error-message">
          {results['<error>']}
          {results['<traceback>']}
        </pre>
      </div>
    );
  }

  // Then check for regular results structure
  if (typeof results !== 'object' || !Object.keys(results).length) {
    return null;
  }

  function get_actual(actual, status) {
    if (status === -2) {
      console.log('actual:', actual);
      let jsonString = actual
        .replace(/'/g, '"')          // Replace single quotes with double quotes
        .replace(/\s+/g, ' ')        // Replace multiple spaces/newlines with single space
        .replace(/\\r\\n/g, '')      // Remove literal \r\n string
        .replace(/\r\n|\r|\n/g, '')  // Remove actual line breaks
        .replace(/True/g, 'true')    // Replace Python True
        .replace(/False/g, 'false')  // Replace Python False
        .replace(/,\s*}/g, '}')      // Remove trailing commas
        .trim();                     // Remove leading/trailing whitespace

      jsonString = jsonString.replace(/,\s*}/g, '}');
      console.log('jsonString:', jsonString);
      actual = JSON.parse(jsonString);
      if (actual.rec_needed && !actual.rec_cond) {
        return failedRecursionText;
      } else if (actual.n_loops_allowed > -1 && !actual.loops_cond) {
        return failedLoopsText.replace('{n_loops_allowed}', actual.n_loops_allowed).replace('{actual_loops}', actual.actual_loops);
      } else {
        return failedStructureText;
      }
    } else {
      return JSON.stringify(actual).slice(1, -1);
    }
  }

  // Determine the maximum number of tests any function has
  const maxTests = Math.max(...Object.values(results).map(funcTests => 
    Array.isArray(funcTests) ? funcTests.length : 0
  ));

  return (
    <div className="table-container">
      <table>
        <thead className="table-header">
          <tr>
            <th>{functionNameText}</th>
            <th>{timeComplexityText}</th>
            {[...Array(maxTests).keys()].map(i => (
              <th key={i}>{testText} {i + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(results).map((functionName, index) => {
            // Ensure the function's tests are an array
            const functionTests = Array.isArray(results[functionName]) ? results[functionName] : [];
            
            return (
              <tr key={functionName} className="table-row">
                <td className="function-name-cell">
                  <div className="function-name-container">
                    <span>{functionName}</span>
                    <button 
                      className="copy-code-button"
                      onClick={() => copyCode(functionName)}
                      title={I18n.t('results.copyLastCode')}
                    >
                      <i className="fas fa-copy"></i>
                      {copySuccess === functionName && (
                        <span className="copy-success-message">
                          {I18n.t('results.codeCopied')}
                        </span>
                      )}
                    </button>
                  </div>
                </td>

                {/* Time Complexity Column */}
                {functionTests.some(test => test.test_name === "time_complexity") ? (
                  functionTests.filter(test => test.test_name === "time_complexity").map((test, i) => (
                    <td 
                      key={i}
                      className={`tooltip ${test.status < 0 ? 'status-error'
                        : test.status === 0 ? 'status-fail'
                        : 'status-pass'}`}
                    >
                      <span className="tooltip-trigger">
                        {test.status < 0 ? errorText
                          : test.status === 0 ? failText
                          : passText}
                      </span>
                      <div className="tooltip-content">
                        {[
                          ['Expected output', test.expected],
                          ['Actual output', test.actual]
                        ].map(([label, value], index) => (
                          <div key={index} className="tooltip-section">
                            <div className="tooltip-label">{label}</div>
                            <div className="tooltip-value">{value}</div>
                          </div>
                        ))}
                      </div>
                    </td>
                  ))
                ) : (
                  <td className="status-no-test">
                    {noLimitText}
                  </td>
                )}

                {/* Test Columns */}
                {functionTests.filter(test => test.test_name !== "time_complexity").map((test, i) => (
                  <td 
                    key={i} 
                    className={`tooltip ${test.status < 0 ? 'status-error'
                      : test.status === 0 ? 'status-fail'
                      : 'status-pass'}`}
                  >
                    <span className="tooltip-trigger">
                      {test.status < 0 ? errorText
                        : test.status === 0 ? failText
                        : passText}
                    </span>
                    <div className="tooltip-content">
                      {[
                        [inputText, test.stringified_input],
                        [expectedOutputText, JSON.stringify(test.expected).slice(1, -1)],
                        [actualOutputText, get_actual(test.actual, test.status)]
                      ].map(([label, value], index) => (
                        <div key={index} className="tooltip-section">
                          <div className="tooltip-label">
                            {label}
                            {label === inputText && (
                              <button 
                                className="copy-button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(value);
                                }}
                                title={I18n.t('results.copyInput')}
                              >
                                <i className="fas fa-copy"></i>
                              </button>
                            )}
                          </div>
                          <div className="tooltip-value">{value}</div>
                        </div>
                      ))}
                    </div>
                  </td>
                ))}

                {/* Fill remaining cells with "No Test" */}
                {[...Array(maxTests - functionTests.filter(test => test.test_name !== "time_complexity").length).keys()].map(i => (
                  <td key={i + functionTests.length} className="status-no-test">
                    {noTestText}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ResultsTable;
