// src/components/SignUp.js
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import UserPool from '../cognitoConfig';
import '../css/SignInSignUp.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import config from '../config';
import { sanitizeEmail, sanitizeName } from './securityFunctions';
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const SignUp = ({ onSignIn }) => {
  const { language } = useContext(LanguageContext); // Get the language from context
  const [name, setName] = useState('');
  const [institute, setInstitute] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [user, setUser] = useState(null);
  const apiUrl = config.API_URL;
  const userPool = config.USER_POOL_ID;

  const navigate = useNavigate();  // Initialize useNavigate inside the component

  // State variables for translated text
  const [signUpText, setSignUpText] = useState(I18n.t('sign.up'));
  const [confirmAccountText, setConfirmAccountText] = useState(I18n.t('sign.confirmAccount'));
  const [nameText, setNameText] = useState(I18n.t('sign.name'));
  const [instituteText, setInstituteText] = useState(I18n.t('sign.institutions.select'));
  const [emailText, setEmailText] = useState(I18n.t('sign.email'));
  const [passwordText, setPasswordText] = useState(I18n.t('sign.password'));
  const [confirmationCodeText, setConfirmationCodeText] = useState(I18n.t('sign.confirmationCode'));
  const [confirmNowText, setConfirmNowText] = useState(I18n.t('sign.confirmAccountNow'));
  const [resendCodeText, setResendCodeText] = useState(I18n.t('sign.resendCode'));

  // Add new state variables for institution names
  const [hujiText, setHujiText] = useState(I18n.t('sign.institutions.huji'));
  const [technionText, setTechnionText] = useState(I18n.t('sign.institutions.technion'));
  const [tauText, setTauText] = useState(I18n.t('sign.institutions.tau'));
  const [bguText, setBguText] = useState(I18n.t('sign.institutions.bgu'));
  const [arielText, setArielText] = useState(I18n.t('sign.institutions.ariel'));

  // Update translations when the language changes
  useEffect(() => {
    setSignUpText(I18n.t('sign.up'));
    setConfirmAccountText(I18n.t('sign.confirmAccount'));
    setNameText(I18n.t('sign.name'));
    setInstituteText(I18n.t('sign.institutions.select'));
    setEmailText(I18n.t('sign.email'));
    setPasswordText(I18n.t('sign.password'));
    setConfirmationCodeText(I18n.t('sign.confirmationCode'));
    setConfirmNowText(I18n.t('sign.confirmAccountNow'));
    setResendCodeText(I18n.t('sign.resendCode'));
    setHujiText(I18n.t('sign.institutions.huji'));
    setTechnionText(I18n.t('sign.institutions.technion'));
    setTauText(I18n.t('sign.institutions.tau'));
    setBguText(I18n.t('sign.institutions.bgu'));
    setArielText(I18n.t('sign.institutions.ariel'));
  }, [language]);

  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationStep, setVerificationStep] = useState('');

  const handleSignUp = (e) => {
    e.preventDefault();

    const sanitizedName = sanitizeName(name);
    if (typeof sanitizedName === 'string' && sanitizedName.startsWith('Name must be')) {
      alert(sanitizedName);
      return;
    }
    
    const sanitizedEmail = sanitizeEmail(email);
    if (typeof sanitizedEmail === 'string' && (sanitizedEmail.startsWith('Email must be') || sanitizedEmail.startsWith('Invalid email'))) {
      alert(sanitizedEmail);
      return;
    }


    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: sanitizedEmail }),
      new CognitoUserAttribute({ Name: 'name', Value: sanitizedName }),
    ];

    UserPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }

      console.log('Sign-up successful:', result);
      setUser(new CognitoUser({ Username: sanitizedEmail, Pool: UserPool }));
      setIsConfirming(true);
    });
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    setError(null);
  
    const sanitizedEmail = sanitizeEmail(email);
    if (typeof sanitizedEmail === 'string' && (sanitizedEmail.startsWith('Email must be') || sanitizedEmail.startsWith('Invalid email'))) {
      alert(sanitizedEmail);
      setIsVerifying(false);
      return;
    }

    if (!user) {
      setError("No user context available. Please sign up again.");
      setIsVerifying(false);
      return;
    }
  
    try {
      // Step 1: Confirm Registration
      setVerificationStep(I18n.t('sign.verifying'));
      await new Promise((resolve, reject) => {
        user.confirmRegistration(confirmationCode, true, (err, result) => {
          if (err) reject(err);
          else resolve(result);
        });
      });
  
      // Step 2: Authenticate User
      setVerificationStep(I18n.t('sign.authenticating'));
      const authDetails = new AuthenticationDetails({
        Username: sanitizedEmail,
        Password: password,
      });
  
      const session = await new Promise((resolve, reject) => {
        user.authenticateUser(authDetails, {
          onSuccess: (data) => resolve(data),
          onFailure: (err) => reject(err),
        });
      });
  
      // Step 3: Get User Attributes
      setVerificationStep(I18n.t('sign.gettingUserInfo'));
      const attributes = await new Promise((resolve, reject) => {
        user.getUserAttributes((err, attributes) => {
          if (err) reject(err);
          else resolve(attributes);
        });
      });
  
      const nameAttr = attributes.find(attr => attr.Name === 'name');
      const subAttr = attributes.find(attr => attr.Name === 'sub');
      
      const userInfo = {
        uuid: subAttr ? subAttr.Value : null,
        user_pool: userPool,
        expiration_date: 0,
        type: 'student',
        name: nameAttr ? nameAttr.Value : null,
        email: sanitizedEmail,
        institute: institute,
        department: '',
      };
  
      // Step 4: Create User in Database
      setVerificationStep(I18n.t('sign.creatingAccount'));
      await axios.post(`${apiUrl}/users/create_user`, userInfo);
      
      onSignIn(user, userInfo.name);
      navigate('/');
    } catch (error) {
      console.error('Verification error:', error);
      setError(error.message || JSON.stringify(error));
    } finally {
      setIsVerifying(false);
      setVerificationStep('');
    }
  };

  const handleResendCode = () => {
    if (!user) {
      setError("No user context available. Please sign up again.");
      return;
    }

    user.resendConfirmationCode((err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
      console.log('Confirmation code resent:', result);
      setError('A new confirmation code has been sent to your email.');
    });
  };

  return (
    <div className="auth-container">
      {!isConfirming ? (
        <form className="auth-form" onSubmit={handleSignUp}>
          <h1>{signUpText}</h1>
          <input
            type="text"
            value={name}
            placeholder={nameText}
            onChange={(e) => setName(e.target.value)}
          />
          <select
            value={institute}
            onChange={(e) => setInstitute(e.target.value)}
            required
          >
            <option value="">{instituteText}</option>
            <option value="huji">{hujiText}</option>
            <option value="technion">{technionText}</option>
            <option value="tau">{tauText}</option>
            <option value="bgu">{bguText}</option>
            <option value="ariel">{arielText}</option>
          </select>
          <input
            type="email"
            value={email}
            placeholder={emailText}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            value={password}
            placeholder={passwordText}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">{signUpText}</button>
          {error && <p>{error}</p>}
        </form>
      ) : (
        <form className="auth-form" onSubmit={handleConfirm}>
          <h1>{confirmAccountText}</h1>
          <input
            type="text"
            value={confirmationCode}
            placeholder={confirmationCodeText}
            onChange={(e) => setConfirmationCode(e.target.value)}
            disabled={isVerifying}
          />
          <button type="submit" disabled={isVerifying}>
            {isVerifying ? verificationStep : confirmNowText}
          </button>
          <button 
            type="button" 
            onClick={handleResendCode}
            disabled={isVerifying}
          >
            {resendCodeText}
          </button>
          {error && <p className="error-message">{error}</p>}
          {isVerifying && <div className="verification-status">{verificationStep}</div>}
        </form>
      )}
    </div>
  );
};

export default SignUp;