import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import I18n from '../i18n';
import '../css/AdminPage.css';
import { LanguageContext } from './LanguageContext';
import DisplayQuestionTable from './tables/DisplayQuestionTable';
import DisplayTestTable from './tables/DisplayTestTable';
import DisplayTestableTable from './tables/DisplayTestableTable';
import StatusMessage from './common/StatusMessage';
import LoadingSpinner from './common/LoadingSpinner';
import TestableRow from './tables/TestableRow';
import DuplicateQuestionDialog from './dialogs/DuplicateQuestionDialog';

const validateAndParseVersion = (value) => {
  // Case 1: Number followed by letter(s) (e.g., "1a", "2beta")
  const numberLetterMatch = value.match(/^(\d+)([a-zA-Z]+)$/);
  if (numberLetterMatch) {
    return {
      isValid: true,
      version_int: parseInt(numberLetterMatch[1]),
      version_str: numberLetterMatch[2]
    };
  }

  // Case 2: Only number
  const numberMatch = value.match(/^\d+$/);
  if (numberMatch) {
    return {
      isValid: true,
      version_int: parseInt(value),
      version_str: ''
    };
  }

  // Case 3: Only letters
  const letterMatch = value.match(/^[a-zA-Z]+$/);
  if (letterMatch) {
    return {
      isValid: true,
      version_int: 0,
      version_str: value
    };
  }

  // Invalid format
  return {
    isValid: false,
    message: I18n.t('admin.messages.invalidVersion')
  };
};

const validateExpectedOutput = (value) => {
  // Skip validation if empty
  if (!value || value.trim() === '') return true;

  // Check if it's a number
  if (!isNaN(value)) return true;

  // Check if it's a Python boolean or None
  if (['True', 'False', 'None'].includes(value)) return true;

  // Check if it starts with {, [, or ( for collections
  if (value.trim().startsWith('{') || 
      value.trim().startsWith('[') || 
      value.trim().startsWith('(')) return true;

  // Check if it's properly quoted (single or double quotes)
  if ((value.startsWith("'") && value.endsWith("'")) || 
      (value.startsWith('"') && value.endsWith('"'))) return true;

  return false;
};

const AdminQuestionsPage = ({ userInfo }) => {
  const navigate = useNavigate();
  const apiUrl = config.API_URL;
  const { language } = useContext(LanguageContext);

  const validatePythonDict = (str) => {
    if (str.trim() === '{}') return true;
    
    const pythonDictRegex = /^\{(\s*['"][^'"]+['"]\s*:\s*[^,}]*\s*,?\s*)*\}$/;
    return pythonDictRegex.test(str);
  };

  const [questions, setQuestions] = useState([]);
  const [tests, setTests] = useState([]);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingTestId, setEditingTestId] = useState(null);
  const [editingData, setEditingData] = useState(null);
  const [editingDataMap, setEditingDataMap] = useState({});
  const [duplicatedQuestions, setDuplicatedQuestions] = useState([]);
  const [duplicatedTests, setDuplicatedTests] = useState([]);
  const [newQuestion, setNewQuestion] = useState(null);
  const [newTest, setNewTest] = useState(null);
  const newRowRef = useRef(null);

  const [title, setTitle] = useState(I18n.t('admin.title'));
  const [upload, setUpload] = useState(I18n.t('admin.upload'));
  const [verify, setVerify] = useState(I18n.t('admin.verify'));
  const [alertNoFile, setAlertNoFile] = useState(I18n.t('admin.alertNoFile'));
  const [alertSuccess, setAlertSuccess] = useState(I18n.t('admin.alertSuccess'));
  const [alertPleaseUpload, setAlertPleaseUpload] = useState(I18n.t('admin.alertPleaseUpload'));
  const [defaultText, setDefaultText] = useState(I18n.t('admin.default'));
  const [loading, setLoading] = useState(I18n.t('basic.loading'));
  const [duplicateTestableNameExists, setDuplicateTestableNameExists] = useState(I18n.t('admin.messages.duplicateTestableNameExists'));


  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState('success');

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [selectedTestableId, setSelectedTestableId] = useState(null);
  const [testables, setTestables] = useState([]);

  // Add new state to track pending test duplicates
  const [pendingTestDuplicates, setPendingTestDuplicates] = useState(new Map());

  // Add new state for testables
  const [editingTestableId, setEditingTestableId] = useState(null);
  const [newTestable, setNewTestable] = useState(null);
  const [duplicatedTestables, setDuplicatedTestables] = useState([]);

  // Add loading states
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState('');

  // Add state for dialog
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [questionToDuplicate, setQuestionToDuplicate] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setTitle(I18n.t('admin.title'));
    setUpload(I18n.t('admin.upload'));
    setVerify(I18n.t('admin.verify'));
    setAlertNoFile(I18n.t('admin.alertNoFile'));
    setAlertSuccess(I18n.t('admin.alertSuccess'));
    setAlertPleaseUpload(I18n.t('admin.alertPleaseUpload'));
    setDefaultText(I18n.t('admin.default'));
    setLoading(I18n.t('basic.loading'));
    setDuplicateTestableNameExists(I18n.t('admin.messages.duplicateTestableNameExists'));
  }, [language]); // Rerun whenever the language changes
  
  useEffect(() => {
    if (userInfo !== null) {
      const isAdmin = userInfo && userInfo.type === 'admin';
      if (!isAdmin) {
        navigate('/'); 
      }
    }
  }, [userInfo, navigate]);

  // Fetch questions and tests on component mount
  useEffect(() => {
    fetchQuestions();
    fetchTestables();
    fetchTests();
  }, []);

  const fetchQuestions = async () => {
    setIsLoading(true);
    setLoadingAction('fetching');
    try {
      const response = await axios.get(`${apiUrl}/questions/get_all_questions`);
      setQuestions(response.data.questions);
    } catch (error) {
      console.error('Error fetching questions:', error);
      showStatus(I18n.t('admin.messages.fetchError'), 'error');
    } finally {
      setIsLoading(false);
      setLoadingAction('');
    }
  };

  const fetchTests = async () => {
    try {
      const response = await axios.get(`${apiUrl}/tests/get_all_tests`);
      setTests(response.data.tests);
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };

  const fetchTestables = async () => {
    try {
      const response = await axios.get(`${apiUrl}/testables/get_all_testables`);
      setTestables(response.data.testables);
    } catch (error) {
      console.error('Error fetching testables:', error);
    }
  };

  const handleQuestionEdit = (question) => {
    // Create the initial version string by concatenating version_int and version_str
    const initialVersionStr = `${question.version_int || ''}${question.version_str || ''}`;
    
    const editData = {
      ...question,
      version_str: initialVersionStr  // Store the concatenated version in version_str for editing
    };

    if (question.id.toString().startsWith('dup_')) {
      setEditingDataMap(prev => ({
        ...prev,
        [question.id]: editData
      }));
    } else {
      setEditingData(editData);
    }
    setEditingQuestionId(question.id);
  };

  const handleTestEdit = (test) => {
    setEditingDataMap(prev => ({
      ...prev,
      [test.id]: { ...test }
    }));
    setEditingTestId(test.id);
    setEditingData(test);
  };

  const isDuplicateName = (items, name, currentId = null) => {
    return items.some(item => 
      item.id !== currentId && 
      item.name.toLowerCase() === name.toLowerCase()
    );
  };

  const handleQuestionSave = async (questionOrId) => {
    setIsLoading(true);
    setLoadingAction('saving');
    try {
      const question = typeof questionOrId === 'string' ? 
        editingDataMap[questionOrId] || questions.find(q => q.id === questionOrId) : 
        questionOrId;

      if (!question || typeof question !== 'object') {
        throw new Error(`Invalid question data: ${JSON.stringify(questionOrId)}`);
      }

      if (!question.name || !question.name.trim()) {
        throw new Error(`Name is required. Current value: "${question.name}"`);
      }

      if (isDuplicateName(questions, question.name, question.id)) {
        throw new Error(I18n.t('admin.messages.duplicateQuestionName'));
      }

      const questionData = {
        name: question.name.trim(),
        text: question.text || '',
        difficulty: Number(question.difficulty) || 1,
        solution: question.solution || ''
      };

      const isNew = question.id?.toString().startsWith('new_') || 
                   question.id?.toString().startsWith('dup_');

      let savedQuestionResponse;
      if (isNew) {
        savedQuestionResponse = await axios.post(
          `${apiUrl}/questions/add_question`,
          questionData,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        );
      } else {
        savedQuestionResponse = await axios.put(
          `${apiUrl}/questions/update/${question.id}`,
          questionData,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        );
      }

      if (!savedQuestionResponse?.data) {
        throw new Error('No data returned from server');
      }

      // Clear edit states first
      setEditingQuestionId(null);
      setEditingData(null);
      setNewQuestion(null);
      setEditingDataMap(prev => {
        const newMap = {...prev};
        delete newMap[question.id];
        return newMap;
      });

      // Then update the questions list with the server response
      await fetchQuestions(); // Re-fetch all questions to ensure we have the latest data

      showStatus(I18n.t('admin.messages.saveSuccess'));

    } catch (error) {
      console.error('Error saving question:', error);
      showStatus(error.message || I18n.t('admin.messages.saveError'), 'error');
    } finally {
      setIsLoading(false);
      setLoadingAction('');
    }
  };

  const handleTestSave = async (test) => {
    try {
      // Map name to test_name for validation
      if (!test.test_name?.trim()) {
        throw new Error(I18n.t('admin.messages.nameRequired'));
      }

      // Check for duplicate names within the same testable
      const testableTests = tests.filter(t => t.testable_id === selectedTestableId);
      if (isDuplicateName(testableTests.map(t => ({ ...t, name: t.test_name })), test.test_name, test.id)) {
        throw new Error(I18n.t('admin.messages.duplicateTestName'));
      }

      let savedTest;
      const isNew = test.id?.toString().startsWith('new_') || 
                   test.id?.toString().startsWith('dup_');

      const payload = {
        test_name: test.test_name.trim(),
        input: test.input || '',
        expected: test.expected || '',
        testable_id: selectedTestableId,
        allow_each_expected: test.allow_each_expected || 0,
        postprocess: test.postprocess || '',
        init_input: test.init_input || ''
      };

      if (isNew) {
        const response = await axios.post(
          `${apiUrl}/tests/add_test`,
          payload,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        );
        savedTest = response.data;
      } else {
        const response = await axios.put(
          `${apiUrl}/tests/update/${test.id}`,
          payload,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        );
        savedTest = response.data;
      }

      // Clear edit states first
      setEditingTestId(null);
      setEditingData(null);
      setNewTest(null);
      setEditingDataMap(prev => {
        const newMap = {...prev};
        delete newMap[test.id];
        return newMap;
      });

      // Then update the tests list with the server response
      await fetchTests();

      showStatus(I18n.t('admin.messages.saveSuccess'));
    } catch (error) {
      console.error('Error saving test:', error);
      showStatus(error.message || I18n.t('admin.messages.saveError'), 'error');
    }
  };

  const handleQuestionDelete = async (questionOrId) => {
    setIsLoading(true);
    setLoadingAction('deleting');
    try {
      // Extract the ID properly
      const questionId = typeof questionOrId === 'object' ? questionOrId.id : questionOrId;
      
      console.log('Deleting question with ID:', questionId); // Debug log
      
      if (!questionId) {
        throw new Error('Invalid question ID');
      }

      await axios.delete(`${apiUrl}/questions/delete_question_id/${questionId}`);
      
      // Update the UI
      setQuestions(questions.filter(q => q.id !== questionId));
      setSelectedQuestionId(null);
      showStatus(I18n.t('admin.messages.deleteSuccess'));
    } catch (error) {
      console.error('Error deleting question:', error);
      showStatus(I18n.t('admin.messages.deleteError'), 'error');
    } finally {
      setIsLoading(false);
      setLoadingAction('');
    }
  };

  const handleTestDelete = async (testId) => {
    if (window.confirm(I18n.t('admin.confirmDeleteTest'))) {
      try {
        await axios.delete(`${apiUrl}/tests/delete_test_id/${testId}`);
        fetchTests();
        showStatus(I18n.t('admin.messages.deleteSuccess'));
      } catch (error) {
        console.error('Error deleting test:', error);
        showStatus(I18n.t('admin.messages.deleteError'), 'error');
      }
    }
  };

  const handleAddQuestion = () => {
    const newQuestionData = {
      id: `new_${Date.now()}`,
      name: '',
      text: '',
      difficulty: 1
    };
    setNewQuestion(newQuestionData);
    setEditingData(newQuestionData);
    setTimeout(() => {
      newRowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  const handleAddTest = (questionId) => {
    const newTestData = {
      id: `new_${Date.now()}`,  // Add temporary ID
      question_id: questionId,
      test_name: 'test_',
      input: '',
      expected: '',
      allow_each_expected: 0
    };
    setNewTest(newTestData);
    setEditingData(newTestData);  // Set editing data to enable edit mode
    setTimeout(() => {
      newRowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  const handleQuestionRowClick = (questionId) => {
    setSelectedQuestionId(questionId === selectedQuestionId ? null : questionId);
    setSelectedTestableId(null); // Clear selected testable when question changes
  };

  const handleQuestionDuplicate = (question) => {
    setQuestionToDuplicate(question);
    setShowDuplicateDialog(true);
  };

  const handleTestDuplicate = (test) => {
    const duplicateId = `dup_${Date.now()}`;
    const duplicateTest = {
      ...test,
      id: duplicateId,
      test_name: `${test.test_name}_copy`
    };

    // Only set up edit mode, don't add to tests list yet
    setEditingDataMap(prev => ({
      ...prev,
      [duplicateId]: duplicateTest
    }));
    
    setEditingTestId(duplicateId);
    setEditingData(duplicateTest);
  };

  const handleQuestionCancel = (questionOrId) => {
    const id = typeof questionOrId === 'object' ? questionOrId.id : questionOrId;
    
    // If this was a duplicated question with pending tests, clean them up
    if (id?.toString().startsWith('dup_')) {
      setPendingTestDuplicates(prev => {
        const newMap = new Map(prev);
        newMap.delete(id);
        return newMap;
      });
      setDuplicatedQuestions(prev => prev.filter(qId => qId !== id));
    }
    
    setEditingQuestionId(null);
    setEditingData(null);
    setNewQuestion(null);  // Always clear newQuestion on cancel
  };

  const handleTestCancel = (testId) => {
    // Clear all edit states
    setEditingTestId(null);
    setEditingData(null);
    
    // Clear from editingDataMap if it's a duplicate
    if (testId?.toString().startsWith('dup_')) {
      setEditingDataMap(prev => {
        const newMap = {...prev};
        delete newMap[testId];
        return newMap;
      });
    }

    if (newTest) {
      setNewTest(null);
    }
  };

  // Add this utility function near the top with other API calls
  const saveQuestion = async (question) => {
    try {
      if (question.id === undefined || question.id === null || question.id.toString().startsWith('dup_') || question.id.toString().startsWith('new_')) {
        // For new questions and duplicates
        const questionToSave = {
          ...question,
          id: undefined
        };
        const response = await axios.post(`${apiUrl}/questions/add_question`, questionToSave);
        return response.data;
      } else {
        // For updating existing questions - fixed endpoint
        const response = await axios.put(`${apiUrl}/questions/update/${question.id}`, question);
        return response.data;
      }
    } catch (error) {
      console.error('Error saving question:', error);
      if (error.response?.data) {
        console.error('Server error details:', error.response.data);
      }
      // Show error to user
    }
  };

  // Add handler for testable row selection
  const handleTestableRowClick = (testableId) => {
    setSelectedTestableId(testableId === selectedTestableId ? null : testableId);
  };

  // Add handler for testable addition
  const handleAddTestable = (questionId) => {
    const newTestableData = {
      id: `new_${Date.now()}`,
      question_id: questionId,
      name: '',
      type: '',
      class_name: '',
      init_input: '',
      time_complexity: '',
      is_recursive: 0,
      max_loop_count: 0
    };
    setNewTestable(newTestableData);
    setEditingData(newTestableData);
    setTimeout(() => {
      newRowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  };

  // Add testable handlers
  const handleTestableEdit = (testable) => {
    setEditingTestableId(testable.id);
    setEditingData({ ...testable });
  };

  const handleTestableDelete = async (testableId) => {
    try {
      await axios.delete(`${apiUrl}/testables/delete_testable_id/${testableId}`);
      setTestables(testables.filter(t => t.id !== testableId));
      setSelectedTestableId(null);
      showStatus(I18n.t('admin.messages.deleteSuccess'));
    } catch (error) {
      console.error('Error deleting testable:', error);
      showStatus(I18n.t('admin.messages.deleteError'), 'error');
    }
  };

  // Add this utility function near the top with other utility functions
  const checkTestableName = async (name, apiUrl) => {
    try {
      const response = await axios.get(`${apiUrl}/testables/is_testable_name_unique/${name}`);
      return response.data.is_unique;
    } catch (error) {
      console.error('Error checking testable name:', error);
      return false; // Fail safe - assume name exists if check fails
    }
  };

  // Modify handleTestableSave to include name validation
  const handleTestableSave = async (testable) => {
    try {
      setIsLoading(true);
      
      // Validate name
      if (!testable.name?.trim()) {
        throw new Error(I18n.t('admin.messages.nameRequired'));
      }

      const isNew = testable.id?.toString().startsWith('new_') || testable === newTestable;

      // Check name uniqueness for both new testables and name changes
      const originalTestable = testables.find(t => t.id === testable.id);
      if (isNew || (originalTestable && originalTestable.name !== testable.name)) {
        const isUnique = await checkTestableName(testable.name, apiUrl);
        if (!isUnique) {
          showStatus(I18n.t('admin.messages.testableNameExists'), 'error');
          return;
        }
      }

      let savedTestable;
      const payload = {
        name: testable.name.trim(),
        type: testable.type || '',
        class_name: testable.class_name || '',
        init_input: testable.init_input || '',
        question_id: selectedQuestionId,
        time_complexity: testable.time_complexity || 'None',
        is_recursive: testable.is_recursive === 1 ? 1 : 0,
        max_loop_count: testable.max_loop_count === 0 ? 0 : testable.max_loop_count || -1
      };

      if (isNew) {
        const response = await axios.post(
          `${apiUrl}/testables/add_testable`, 
          payload,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        savedTestable = response.data;
      } else {
        const response = await axios.put(
          `${apiUrl}/testables/update/${testable.id}`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        savedTestable = response.data;
      }

      // Clear edit states first
      setEditingTestableId(null);
      setEditingData(null);
      setNewTestable(null);
      setEditingDataMap(prev => {
        const newMap = {...prev};
        delete newMap[testable.id];
        return newMap;
      });

      // Then update the testables list with the server response
      await fetchTestables();

      showStatus(I18n.t('admin.messages.saveSuccess'));
    } catch (error) {
      console.error('Error saving testable:', error);
      showStatus(error.message || I18n.t('admin.messages.saveError'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTestableDuplicate = (testable) => {
    const duplicateId = `dup_${Date.now()}`;
    const duplicateTestable = {
      ...testable,
      id: duplicateId,
      name: `${testable.name}_copy`
    };

    // Only set up edit mode, don't add to testables list yet
    setEditingDataMap(prev => ({
      ...prev,
      [duplicateId]: duplicateTestable
    }));
    
    setEditingTestableId(duplicateId);
    setEditingData(duplicateTestable);
  };

  const handleTestableCancel = () => {
    setEditingTestableId(null);
    setEditingData(null);
    if (newTestable) {
      setNewTestable(null);
    }
  };

  // Add new handlers
  const handleSimpleDuplicate = async (newName) => {
    const duplicateId = `dup_${Date.now()}`;
    const duplicateQuestion = {
      ...questionToDuplicate,
      id: duplicateId,
      name: newName
    };

    setEditingDataMap(prev => ({
      ...prev,
      [duplicateId]: duplicateQuestion
    }));
    
    setEditingQuestionId(duplicateId);
    setEditingData(duplicateQuestion);
    setShowDuplicateDialog(false);
    setQuestionToDuplicate(null);
  };

  const handleFullDuplicate = async (newQuestionName, testablesNames) => {
    try {
      setIsLoading(true);
      
      const questionPayload = {
        ...questionToDuplicate,
        name: newQuestionName,
        id: undefined
      };

      // Check testable names
      for (const newTestableName of Object.values(testablesNames)) {
        const isUnique = await checkTestableName(newTestableName, apiUrl);
        if (!isUnique) {
          showStatus(
            duplicateTestableNameExists.replace('{name}', newTestableName), 
            'error'
          );
          return;
        }
      }

      // Duplicate the question
      const questionResponse = await axios.post(
        `${apiUrl}/questions/add_question`,
        questionPayload
      );
      const newQuestionId = questionResponse.data.question_id;

      // Get all tags for the question
      const tagsResponse = await axios.get(`${apiUrl}/tags/all_questions_with_tags`);
      const originalTags = tagsResponse.data?.questions?.[questionToDuplicate.id] || [];

      // Duplicate the tags
      for (const tag of originalTags) {
        await axios.post(
          `${apiUrl}/tags/add_tag_to_question/${newQuestionId}?label=${encodeURIComponent(tag.label)}`
        );
      }

      // Then duplicate each testable
      for (const [oldTestableId, newTestableName] of Object.entries(testablesNames)) {
        const oldTestable = testables.find(t => t.id === parseInt(oldTestableId));
        if (!oldTestable) continue;

        const testablePayload = {
          ...oldTestable,
          name: newTestableName,
          question_id: newQuestionId,
          id: undefined  // Let the server assign a new ID
        };

        const testableResponse = await axios.post(
          `${apiUrl}/testables/add_testable`,
          testablePayload
        );
        const newTestableId = testableResponse.data.testable_id;

        // Finally duplicate all tests for this testable
        const testsForTestable = tests.filter(t => t.testable_id === parseInt(oldTestableId));
        for (const test of testsForTestable) {
          const testPayload = {
            ...test,
            testable_id: newTestableId,
            id: undefined  // Let the server assign a new ID
          };

          await axios.post(
            `${apiUrl}/tests/add_test`,
            testPayload
          );
        }
      }

      // Refresh all data
      await Promise.all([
        fetchQuestions(),
        fetchTestables(),
        fetchTests()
      ]);
      
      showStatus(I18n.t('admin.messages.duplicateSuccess'));
    } catch (error) {
      console.error('Error duplicating question:', error);
      showStatus(I18n.t('admin.messages.duplicateError'), 'error');
    } finally {
      setIsLoading(false);
      setShowDuplicateDialog(false);
      setQuestionToDuplicate(null);
    }
  };

  // Add this function to handle status messages
  const showStatus = (message, type = 'success') => {
    setStatusMessage(message);
    setStatusType(type);
    setTimeout(() => setStatusMessage(null), 3000); // Clear after 3 seconds
  };

  // Add this function to filter questions
  const filteredQuestions = questions.filter(question => 
    question.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (userInfo === null) {
    // Optionally render a loading indicator while userInfo is being fetched
    return <div>{loading}</div>;
  }

  return (
    <div className="admin-page">
      <h2>{title}</h2>
      {isLoading && <LoadingSpinner overlay />}
      <StatusMessage 
        message={statusMessage}
        type={statusType}
        onClose={() => setStatusMessage(null)}
      />
      <section className="questions-section">
        <div className="section-header">
          <h3>{I18n.t('admin.questions')}</h3>
          <div className="section-controls">
            <input
              type="text"
              className="search-input"
              placeholder={I18n.t('admin.searchQuestions')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button 
              onClick={handleAddQuestion}
              disabled={isLoading}
            >
              {I18n.t('admin.addQuestion')}
            </button>
          </div>
        </div>
        <DisplayQuestionTable
          questions={filteredQuestions}
          editingQuestionId={editingQuestionId}
          editingData={editingData}
          editingDataMap={editingDataMap}
          setEditingDataMap={setEditingDataMap}
          duplicatedRows={duplicatedQuestions}
          newQuestion={newQuestion}
          newRowRef={newRowRef}
          selectedQuestionId={selectedQuestionId}
          handleQuestionEdit={handleQuestionEdit}
          handleQuestionDelete={handleQuestionDelete}
          handleQuestionSave={handleQuestionSave}
          handleQuestionDuplicate={handleQuestionDuplicate}
          handleQuestionCancel={handleQuestionCancel}
          setEditingQuestionId={setEditingQuestionId}
          setEditingData={setEditingData}
          setNewQuestion={setNewQuestion}
          handleQuestionRowClick={handleQuestionRowClick}
          isLoading={isLoading}
          loadingAction={loadingAction}
        />
      </section>

      {selectedQuestionId && (
        <section className="testables-section">
          <div className="section-header">
            <h3>{I18n.t('admin.testables')} for {questions.find(q => q.id === selectedQuestionId)?.name}</h3>
            <button onClick={() => handleAddTestable(selectedQuestionId)}>{I18n.t('admin.addTestable')}</button>
          </div>
          <DisplayTestableTable
            testables={testables.filter(t => t.question_id === selectedQuestionId)}
            questions={questions}
            editingTestableId={editingTestableId}
            editingData={editingData}
            editingDataMap={editingDataMap}
            setEditingDataMap={setEditingDataMap}
            setEditingData={setEditingData}
            duplicatedRows={duplicatedTestables}
            newTestable={newTestable}
            newRowRef={newRowRef}
            selectedTestableId={selectedTestableId}
            handleTestableEdit={handleTestableEdit}
            handleTestableDelete={handleTestableDelete}
            handleTestableSave={handleTestableSave}
            handleTestableDuplicate={handleTestableDuplicate}
            handleTestableCancel={handleTestableCancel}
            handleTestableRowClick={handleTestableRowClick}
          />
        </section>
      )}

      {selectedTestableId && (
        <section className="tests-section">
          <div className="section-header">
            <h3>{I18n.t('admin.tests')} for {testables.find(t => t.id === selectedTestableId)?.name}</h3>
            <button onClick={() => handleAddTest(selectedTestableId)}>{I18n.t('admin.addTest')}</button>
          </div>
          <DisplayTestTable
            tests={tests.filter(test => test.testable_id === selectedTestableId)}
            testables={testables}
            editingTestId={editingTestId}
            editingData={editingData}
            editingDataMap={editingDataMap}
            setEditingDataMap={setEditingDataMap}
            setEditingData={setEditingData}
            duplicatedRows={duplicatedTests}
            newTest={newTest}
            newRowRef={newRowRef}
            handleTestEdit={handleTestEdit}
            handleTestDelete={handleTestDelete}
            handleTestSave={handleTestSave}
            handleTestDuplicate={handleTestDuplicate}
            handleTestCancel={handleTestCancel}
          />
        </section>
      )}

      {showDuplicateDialog && (
        <DuplicateQuestionDialog
          question={questionToDuplicate}
          onCancel={() => {
            setShowDuplicateDialog(false);
            setQuestionToDuplicate(null);
          }}
          onSimpleDuplicate={handleSimpleDuplicate}
          onFullDuplicate={handleFullDuplicate}
        />
      )}
    </div>
  );
};

export default AdminQuestionsPage;