export const QUESTION_COLUMNS = [
  { 
    key: 'actions',
    label: 'admin.tableHeaders.actions',
    tooltip: 'admin.tableHeaders.tooltips.actions',
    className: 'col-actions'
  },
  { 
    key: 'id',
    label: 'admin.tableHeaders.questionId',
    tooltip: 'admin.tableHeaders.tooltips.questionId',
    className: 'col-id'
  },
  { 
    key: 'name',
    label: 'admin.tableHeaders.name',
    tooltip: 'admin.tableHeaders.tooltips.name',
    className: 'col-name'
  },
  { 
    key: 'tags',
    label: 'admin.tableHeaders.tags',
    tooltip: 'admin.tableHeaders.tooltips.tags',
    className: 'col-tags'
  },
  { 
    key: 'solution',
    label: 'admin.tableHeaders.solution',
    tooltip: 'admin.tableHeaders.tooltips.solution',
    className: 'col-solution'
  },
  { 
    key: 'text',
    label: 'admin.tableHeaders.text',
    tooltip: 'admin.tableHeaders.tooltips.text',
    className: 'col-text'
  },
  { 
    key: 'difficulty',
    label: 'admin.tableHeaders.difficulty',
    tooltip: 'admin.tableHeaders.tooltips.difficulty',
    className: 'col-difficulty'
  }
];

export const TESTABLE_COLUMNS = [
  { 
    key: 'actions',
    label: 'admin.tableHeaders.actions',
    tooltip: 'admin.tableHeaders.tooltips.actions',
    className: 'col-actions'
  },
  { 
    key: 'id',
    label: 'admin.tableHeaders.testableId',
    tooltip: 'admin.tableHeaders.tooltips.testableId',
    className: 'col-id'
  },
  { 
    key: 'name',
    label: 'admin.tableHeaders.name',
    tooltip: 'admin.tableHeaders.tooltips.name',
    className: 'col-name'
  },
  { 
    key: 'type',
    label: 'admin.tableHeaders.type',
    tooltip: 'admin.tableHeaders.tooltips.type',
    className: 'col-type'
  },
  { 
    key: 'class_name',
    label: 'admin.tableHeaders.className',
    tooltip: 'admin.tableHeaders.tooltips.className',
    className: 'col-class-name'
  },
  { 
    key: 'init_input',
    label: 'admin.tableHeaders.initInput',
    tooltip: 'admin.tableHeaders.tooltips.initInput',
    className: 'col-init-input'
  },
  { 
    key: 'time_complexity',
    label: 'admin.tableHeaders.timeComplexity',
    tooltip: 'admin.tableHeaders.tooltips.timeComplexity',
    className: 'col-time-complexity'
  },
  { 
    key: 'is_recursive',
    label: 'admin.tableHeaders.recursive',
    tooltip: 'admin.tableHeaders.tooltips.recursive',
    className: 'col-checkbox'
  },
  { 
    key: 'max_loop_count',
    label: 'admin.tableHeaders.maxLoops',
    tooltip: 'admin.tableHeaders.tooltips.maxLoops',
    className: 'col-max-loops'
  }
];

export const TEST_COLUMNS = [
  { 
    key: 'actions',
    label: 'admin.tableHeaders.actions',
    tooltip: 'admin.tableHeaders.tooltips.actions',
    className: 'col-actions'
  },
  { 
    key: 'id',
    label: 'admin.tableHeaders.testId',
    tooltip: 'admin.tableHeaders.tooltips.testId',
    className: 'col-id'
  },
  { 
    key: 'testable_id',
    label: 'admin.tableHeaders.testableName',
    tooltip: 'admin.tableHeaders.tooltips.testableName',
    className: 'col-testable-name'
  },
  { 
    key: 'test_name',
    label: 'admin.tableHeaders.testName',
    tooltip: 'admin.tableHeaders.tooltips.testName',
    className: 'col-test-name'
  },
  { 
    key: 'input',
    label: 'admin.tableHeaders.input',
    tooltip: 'admin.tableHeaders.tooltips.input',
    className: 'col-input'
  },
  { 
    key: 'expected',
    label: 'admin.tableHeaders.expected',
    tooltip: 'admin.tableHeaders.tooltips.expected',
    className: 'col-expected'
  },
  { 
    key: 'allow_each_expected',
    label: 'admin.tableHeaders.allowEachExpected',
    tooltip: 'admin.tableHeaders.tooltips.allowEachExpected',
    className: 'col-checkbox'
  }
];

export const USER_COLUMNS = [
  { 
    key: 'actions',
    label: 'admin.headers.actions',
    tooltip: 'admin.tableHeaders.tooltips.actions',
    className: 'col-actions'
  },
  { 
    key: 'id',
    label: 'admin.headers.userId',
    tooltip: 'admin.tableHeaders.tooltips.userId',
    className: 'col-id'
  },

  { 
    key: 'name',
    label: 'admin.headers.name',
    tooltip: 'admin.tableHeaders.tooltips.name',
    className: 'col-name'

  },
  { 
    key: 'email',
    label: 'admin.headers.email',
    tooltip: 'admin.tableHeaders.tooltips.email',
    className: 'col-email'

  },
  { 
    key: 'expiration_date',
    label: 'admin.headers.expirationDate',
    tooltip: 'admin.tableHeaders.tooltips.expirationDate',
    className: 'col-expiration-date'


  },
  { 
    key: 'institute',
    label: 'admin.headers.institute',
    tooltip: 'admin.tableHeaders.tooltips.institute',
    className: 'col-institute'

  },
  { 
    key: 'department',
    label: 'admin.headers.department',
    tooltip: 'admin.tableHeaders.tooltips.department',
    className: 'col-department'

  }
]; 