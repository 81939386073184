import React, { useState, useEffect } from 'react';
import I18n from '../../i18n';
import QuestionRow from './QuestionRow';
import BaseTable from './BaseTable';
import axios from 'axios';
import config from '../../config';
import TagFilterDialog from '../dialogs/TagFilterDialog';

const DisplayQuestionTable = ({
  questions,
  editingQuestionId,
  editingData,
  editingDataMap,
  setEditingDataMap,
  duplicatedRows = [],
  newQuestion,
  newRowRef,
  selectedQuestionId,
  handleQuestionEdit,
  handleQuestionDelete,
  handleQuestionSave,
  handleQuestionDuplicate,
  handleQuestionCancel,
  setEditingData,
  setNewQuestion,
  handleQuestionRowClick,
  onUpdate,
}) => {
  const [questionTags, setQuestionTags] = useState({});
  const [showTagFilter, setShowTagFilter] = useState(false);
  const [selectedTagFilters, setSelectedTagFilters] = useState([]);
  const [useAndOperator, setUseAndOperator] = useState(false);

  useEffect(() => {
    fetchAllTags();
  }, []);

  const fetchAllTags = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/tags/all_questions_with_tags`);
      setQuestionTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleRefresh = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/tags/all_questions_with_tags`);
      setQuestionTags(response.data);
    } catch (error) {
      console.error('Error refreshing tags:', error);
    }
  };

  const getFilteredQuestions = () => {
    return questions.filter(question => {
      if (!question || selectedTagFilters.length === 0) return true;
      
      const questionTagLabels = questionTags?.questions?.[question.id]?.map(t => t.label) || [];
      
      if (useAndOperator) {
        return selectedTagFilters.every(tag => questionTagLabels.includes(tag));
      } else {
        return selectedTagFilters.some(tag => questionTagLabels.includes(tag));
      }
    });
  };

  const headers = [
    { 
      title: I18n.t('admin.headers.actions'),
      className: 'col-actions',
      tooltip: I18n.t('admin.headers.tooltips.actions')
    },
    { 
      title: I18n.t('admin.headers.questionId'),
      className: 'col-id',
      tooltip: I18n.t('admin.headers.tooltips.questionId')
    },
    { 
      title: I18n.t('admin.headers.name'),
      className: 'col-name',
      tooltip: I18n.t('admin.headers.tooltips.name')
    },
    { 
      title: (
        <div className="tags-header">
          {I18n.t('admin.headers.tags')}
          <button 
            className="tag-filter-button"
            onClick={() => setShowTagFilter(true)}
            title={I18n.t('admin.buttons.filterTags')}
          >
            <i className="fas fa-filter" />
          </button>
          {selectedTagFilters.length > 0 && (
            <button 
              className="clear-filter-button"
              onClick={() => {
                setSelectedTagFilters([]);
                setUseAndOperator(false);
              }}
              title={I18n.t('admin.buttons.clearFilter')}
            >
              <i className="fas fa-times" />
            </button>
          )}
        </div>
      ),
      className: 'col-tags',
      tooltip: I18n.t('admin.headers.tooltips.tags')
    },
    { 
      title: I18n.t('admin.headers.solution'),
      className: 'col-solution',
      tooltip: I18n.t('admin.headers.tooltips.solution')
    },
    { 
      title: I18n.t('admin.headers.text'),
      className: 'col-text',
      tooltip: I18n.t('admin.headers.tooltips.text')
    },
    { 
      title: I18n.t('admin.headers.difficulty'),
      className: 'col-difficulty',
      tooltip: I18n.t('admin.headers.tooltips.difficulty')
    }
  ];

  const renderRow = (question, isEditing, actions) => {
    if (!question) return null;

    const isDuplicate = question.id?.toString().startsWith('dup_');
    const currentEditData = isDuplicate ? editingDataMap[question.id] : editingData;

    const editData = {
      ...question,
      name: (question === newQuestion ? '' : question.name) || '',
      text: (question === newQuestion ? '' : question.text) || '',
      difficulty: (question === newQuestion ? 1 : question.difficulty) || 1
    };

    const enhancedActions = {
      ...actions,
      onCancel: () => {
        actions.onCancel(question.id);
        if (question === newQuestion) {
          setNewQuestion(null);
        }
      },
      onRefresh: handleRefresh
    };

    return (
      <QuestionRow
        key={question.id}
        question={question}
        isEditing={isEditing || question === newQuestion}
        editingData={currentEditData || editData}
        setEditingData={isDuplicate 
          ? (data) => setEditingDataMap(prev => ({...prev, [question.id]: data}))
          : setEditingData
        }
        actions={enhancedActions}
        onUpdate={onUpdate}
        questionTags={questionTags}
      />
    );
  };

  const handleQuestionUpdate = (updatedQuestion) => {
    onUpdate(questions.map(q => 
      q.id === updatedQuestion.id ? updatedQuestion : q
    ));
  };

  const filteredQuestions = getFilteredQuestions();

  return (
    <>
      <BaseTable
        items={filteredQuestions.filter(q => q !== newQuestion || editingData)}
        headers={headers}
        editingId={editingQuestionId}
        onEdit={handleQuestionEdit}
        onDelete={handleQuestionDelete}
        onDuplicate={handleQuestionDuplicate}
        onSave={handleQuestionSave}
        onCancel={handleQuestionCancel}
        renderRow={renderRow}
        selectedId={selectedQuestionId}
        onRowClick={handleQuestionRowClick}
        newItem={newQuestion}
        newRowRef={newRowRef}
      />
      
      {showTagFilter && (
        <TagFilterDialog
          onClose={() => setShowTagFilter(false)}
          onFilter={(tags, andOperator) => {
            setSelectedTagFilters(tags);
            setUseAndOperator(andOperator);
          }}
          selectedTags={selectedTagFilters}
          isAndOperator={useAndOperator}
        />
      )}
    </>
  );
};

export default DisplayQuestionTable; 