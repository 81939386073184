import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';
import I18n from '../../i18n';
import '../../css/Dialog.css';

const EditTagDialog = ({ 
  tag, 
  onClose, 
  onUpdate 
}) => {
  const [newLabel, setNewLabel] = useState(tag.label);
  const [newColor, setNewColor] = useState(tag.color);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const params = new URLSearchParams();
      if (newLabel !== tag.label) {
        params.append('new_label', newLabel);
      }
      if (newColor !== tag.color) {
        params.append('new_color', newColor);
      }

      await axios.put(
        `${config.API_URL}/tags/update_tag/${encodeURIComponent(tag.label)}?${params.toString()}`
      );
      
      onUpdate();
      onClose();
    } catch (error) {
      console.error('Error updating tag:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dialog-overlay edit-tag-dialog-overlay" onClick={e => e.stopPropagation()}>
      <div className="dialog-content edit-tag-dialog">
        <h2>{I18n.t('admin.dialogs.editTag')}</h2>
        
        <div className="dialog-field">
          <label>{I18n.t('admin.labels.tagLabel')}</label>
          <input
            type="text"
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
          />
        </div>

        <div className="dialog-field">
          <label>{I18n.t('admin.labels.tagColor')}</label>
          <input
            type="color"
            value={newColor}
            onChange={(e) => setNewColor(e.target.value)}
          />
        </div>

        <div className="dialog-buttons">
          <button 
            className="cancel-button"
            onClick={onClose}
            disabled={isLoading}
          >
            {I18n.t('admin.buttons.cancel')}
          </button>
          <button 
            className="save-button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {I18n.t('admin.buttons.save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTagDialog; 