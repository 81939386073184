import React from 'react';
import TableActions from './TableActions';

const UserRow = ({
  user,
  isEditing,
  editingData,
  setEditingData,
  actions,
  isLoading,
  loadingAction
}) => {
  const handleChange = (field, value) => {
    setEditingData({
      ...editingData,
      [field]: value
    });
  };

  // Helper function to format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "Not Paying";
    return new Date(dateString).toLocaleDateString();
  };

  // Helper function to format date for input value
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  if (isEditing) {
    return (
      <>
        <TableActions
          item={editingData}
          isEditing={true}
          onSave={actions.onSave}
          onCancel={actions.onCancel}
          buttons={['edit', 'delete']}
          isLoading={isLoading}
          loadingAction={loadingAction}
        />
        <td>{user.id}</td>
        <td>
          <input
            type="text"
            value={editingData?.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </td>
        <td>
          <input
            type="email"
            value={editingData?.email || ''}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </td>
        <td>
          <input
            type="date"
            value={formatDateForInput(editingData?.expiration_date)}
            onChange={(e) => handleChange('expiration_date', e.target.value)}
          />
        </td>
        <td>
          <input
            type="text"
            value={editingData?.institute || ''}
            onChange={(e) => handleChange('institute', e.target.value)}
          />
        </td>
        <td>
          <input
            type="text"
            value={editingData?.department || ''}
            onChange={(e) => handleChange('department', e.target.value)}
          />
        </td>
      </>
    );
  }

  return (
    <>
      <TableActions
        item={user}
        isEditing={false}
        onEdit={actions.onEdit}
        onDelete={actions.onDelete}
        onDetails={actions.onDetails}
        buttons={['details', 'edit', 'delete']}
        isLoading={isLoading}
        loadingAction={loadingAction}
      />
      <td>{user.id}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{formatDate(user.expiration_date)}</td>
      <td>{user.institute}</td>
      <td>{user.department}</td>
    </>
  );
};

export default UserRow; 