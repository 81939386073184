import React, { useState, useEffect } from 'react';
import TableActions from './TableActions';
import SolutionDialog from '../dialogs/SolutionDialog';
import I18n from '../../i18n';
import axios from 'axios';
import config from '../../config';
import TagsDialog from '../dialogs/TagsDialog';

const QuestionRow = ({
  question,
  isEditing,
  editingData,
  setEditingData,
  actions,
  onUpdate,
  questionTags
}) => {
  const [showSolutionDialog, setShowSolutionDialog] = useState(false);
  const [showTextDialog, setShowTextDialog] = useState(false);
  const [showTagsDialog, setShowTagsDialog] = useState(false);
  const [solution, setSolution] = useState(isEditing ? editingData?.solution : question.solution);
  const [text, setText] = useState(isEditing ? editingData?.text : question.text);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setSolution(editingData?.solution);
      setText(editingData?.text);
    } else {
      setSolution(question.solution);
      setText(question.text);
    }
  }, [isEditing, editingData?.solution, editingData?.text, question.solution, question.text]);

  const handleChange = (field, value) => {
    const newData = {
      ...editingData,
      [field]: value
    };
    setEditingData(newData);
  };

  const fetchSolution = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${config.API_URL}/questions/get_question_field_by_id/${question.id}?field=solution`
      );
      setSolution(response.data.value || '');
    } catch (error) {
      console.error('Error fetching solution:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSolutionSave = async (newSolution) => {
    try {
      setIsLoading(true);
      await axios.put(
        `${config.API_URL}/questions/update/${question.id}`,
        { solution: newSolution },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      setSolution(newSolution);
      setShowSolutionDialog(false);
      
      if (isEditing && setEditingData) {
        setEditingData({
          ...editingData,
          solution: newSolution
        });
      }
      
      onUpdate({
        ...question,
        solution: newSolution
      });

      if (actions.onRefresh) {
        await actions.onRefresh();
      }
    } catch (error) {
      console.error('Error saving solution:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditSolution = async () => {
    await fetchSolution();
    setShowSolutionDialog(true);
  };

  const fetchText = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${config.API_URL}/questions/get_question_field_by_id/${question.id}?field=text`
      );
      setText(response.data.value || '');
    } catch (error) {
      console.error('Error fetching text:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTextSave = async (newText) => {
    try {
      setIsLoading(true);
      await axios.put(
        `${config.API_URL}/questions/update/${question.id}`,
        { text: newText },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      setText(newText);
      setShowTextDialog(false);
      
      onUpdate({
        ...question,
        text: newText
      });

      if (actions.onRefresh) {
        await actions.onRefresh();
      }
    } catch (error) {
      console.error('Error saving text:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditText = async () => {
    await fetchText();
    setShowTextDialog(true);
  };

  const renderTags = () => {
    const tags = questionTags?.questions?.[question.id] || [];
    return (
      <div className="tags-container">
        <button 
          className="manage-tags-button"
          onClick={() => setShowTagsDialog(true)}
          title={I18n.t('admin.buttons.manageTags')}
        >
          <i className="fas fa-tags" />
        </button>
        {tags.map((tag, index) => (
          <span
            key={index}
            className="tag-label"
            style={{ backgroundColor: tag.color }}
          >
            {tag.label}
          </span>
        ))}
        {showTagsDialog && (
          <TagsDialog
            question={question}
            questionTags={questionTags}
            onClose={() => setShowTagsDialog(false)}
            onTagsUpdate={actions.onRefresh}
          />
        )}
      </div>
    );
  };

  if (isEditing) {
    return (
      <>
        <TableActions
          item={editingData}
          isEditing={true}
          onSave={actions.onSave}
          onCancel={actions.onCancel}
        />
        <td>{question.id}</td>
        <td>
          <input 
            type="text"
            value={editingData?.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </td>
        <td>{renderTags()}</td>
        <td className={`solution-cell ${solution ? 'has-solution' : 'no-solution'}`}>
          <button 
            className="edit-solution-button"
            onClick={handleEditSolution}
            disabled={true}
            title={I18n.t('admin.buttons.editSolution')}
          >
            <i className="fas fa-pen" />
          </button>
        </td>
        <td>
          <button 
            className="edit-text-button"
            onClick={handleEditText}
            disabled={true}
            title={I18n.t('admin.buttons.editText')}
          >
            <i className="fas fa-pen" />
          </button>
        </td>
        <td>
          <input 
            type="number"
            min="1" 
            value={editingData?.difficulty || 1}
            onChange={(e) => handleChange('difficulty', parseInt(e.target.value))}
          />
        </td>
      </>
    );
  }

  return (
    <>
      <TableActions
        item={question}
        onEdit={actions.onEdit}
        onDuplicate={actions.onDuplicate}
        onDelete={actions.onDelete}
      />
      <td>{question.id}</td>
      <td>{question.name}</td>
      <td>{renderTags()}</td>
      <td className={`solution-cell ${solution ? 'has-solution' : 'no-solution'}`}>
        <button 
          className="edit-solution-button"
          onClick={handleEditSolution}
          disabled={isLoading}
          title={I18n.t('admin.buttons.editSolution')}
        >
          <i className="fas fa-pen" />
        </button>
      </td>
      <td className={`text-cell ${text ? 'has-text' : 'no-text'}`}>
        <button 
          className="edit-text-button"
          onClick={handleEditText}
          disabled={isLoading}
          title={I18n.t('admin.buttons.editText')}
        >
          <i className="fas fa-pen" />
        </button>
      </td>
      <td>{question.difficulty}</td>
      {showSolutionDialog && (
        <SolutionDialog
          question={question}
          initialSolution={solution}
          onSave={handleSolutionSave}
          onCancel={() => setShowSolutionDialog(false)}
          isLoading={isLoading}
        />
      )}
      {showTextDialog && (
        <SolutionDialog
          question={question}
          initialSolution={text}
          onSave={handleTextSave}
          onCancel={() => setShowTextDialog(false)}
          isLoading={isLoading}
          title={I18n.t('admin.dialogs.editText')}
        />
      )}
    </>
  );
};

export default QuestionRow; 