import SecurityConstants from '../constants/SecurityConstants';

// Sanitize Name
export const sanitizeName = (name) => {
    if (!name || name.trim().length === 0) {
        return 'Name must be provided';
    }
    
    // Allow letters from any language, spaces, and common name characters
    const nameRegex = /^[\p{L}\p{M}\s'-]+$/u;
    
    if (!nameRegex.test(name)) {
        return 'Name can only contain letters, spaces, hyphens, and apostrophes';
    }
    
    if (name.length < 2 || name.length > 50) {
        return 'Name must be between 2 and 50 characters';
    }
    
    return name.trim();
};

// Sanitize Email
export function sanitizeEmail(email) {
    const sanitizedEmail = email.trim().toLowerCase();
    if (sanitizedEmail.length > SecurityConstants.SQL_USER_EMAIL_STRING_LIMIT) {
        return `Email must be ${SecurityConstants.SQL_USER_EMAIL_STRING_LIMIT} characters or less`
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(sanitizedEmail)) {
        return "Invalid email address"
    }
    return sanitizedEmail;
    }

export function sanitizeConfirmationCode(code) {
    const sanitized = code.replace(/[^0-9]/g, '').trim();
    if (sanitized.length == SecurityConstants.SQL_CONFIRMATION_CODE_STRING_LIMIT) {
        return `Confirmation code must be ${SecurityConstants.SQL_CONFIRMATION_CODE_STRING_LIMIT} digits`;
    }
    return sanitized;
    }

export function sanitizePythonCode(code) {
    // const sanitizedCode = code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    if (code.length > SecurityConstants.SQL_CODE_STRING_LIMIT) {
        return `Code must be ${SecurityConstants.SQL_CODE_STRING_LIMIT} characters or less`;
    }
    return code;
}