import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import I18n from '../../i18n';
import '../../css/Dialog.css';

const DuplicateQuestionDialog = ({ 
  question, 
  onCancel, 
  onSimpleDuplicate, 
  onFullDuplicate 
}) => {
  const [questionName, setQuestionName] = useState(`${question.name}_copy`);
  const [testables, setTestables] = useState([]);
  const [testablesNames, setTestablesNames] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTestables = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/testables/get_question_testables/${question.id}`
        );
        const fetchedTestables = response.data.testables;
        setTestables(fetchedTestables);
        
        // Initialize names map with default copy names
        const namesMap = {};
        fetchedTestables.forEach(testable => {
          namesMap[testable.id] = `${testable.name}_copy`;
        });
        setTestablesNames(namesMap);
      } catch (error) {
        console.error('Error fetching testables:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTestables();
  }, [question.id]);

  const handleSimpleDuplicate = () => {
    onSimpleDuplicate(questionName);
  };

  const handleFullDuplicate = () => {
    onFullDuplicate(questionName, testablesNames);
  };

  if (isLoading) {
    return <div className="dialog-overlay">
      <div className="dialog-content">
        <div className="loading-spinner"></div>
      </div>
    </div>;
  }

  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <h2>{I18n.t('admin.dialogs.duplicateQuestion')}</h2>
        
        <div className="dialog-field">
          <label>{I18n.t('admin.dialogs.questionName')}:</label>
          <input
            type="text"
            value={questionName}
            onChange={(e) => setQuestionName(e.target.value)}
          />
        </div>

        {testables.length > 0 && (
          <>
            <h3>{I18n.t('admin.dialogs.associatedTestables')}:</h3>
            {testables.map(testable => (
              <div key={testable.id} className="dialog-field">
                <label>{testable.name}:</label>
                <input
                  type="text"
                  value={testablesNames[testable.id]}
                  onChange={(e) => setTestablesNames({
                    ...testablesNames,
                    [testable.id]: e.target.value
                  })}
                />
              </div>
            ))}
          </>
        )}

        <div className="dialog-buttons">
          <button 
            className="cancel-button" 
            onClick={onCancel}
          >
            {I18n.t('admin.buttons.cancel')}
          </button>
          <button 
            className="simple-duplicate-button" 
            onClick={handleSimpleDuplicate}
          >
            {I18n.t('admin.buttons.simpleDuplicate')}
          </button>
          {testables.length > 0 && (
            <button 
              className="full-duplicate-button" 
              onClick={handleFullDuplicate}
            >
              {I18n.t('admin.buttons.fullDuplicate')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DuplicateQuestionDialog; 