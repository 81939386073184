import React from 'react';
import I18n from '../../i18n';

const TableActions = ({ 
  item,
  isEditing,
  onEdit,
  onDelete,
  onDuplicate,
  onSave,
  onCancel,
  onDetails,
  buttons = ['edit', 'duplicate', 'delete'],
  isLoading,
  loadingAction
}) => {
  const handleClick = (action, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isLoading) return;

    // Explicitly identify which action is being called
    const actionType = 
      action === onSave ? 'save' :
      action === onDelete ? 'delete' :
      action === onEdit ? 'edit' :
      action === onDuplicate ? 'duplicate' :
      action === onDetails ? 'details' :
      action === onCancel ? 'cancel' : 'unknown';

    console.log('Action type:', actionType);
    console.log('Item:', item);

    // Make sure we have a valid action function
    if (typeof action !== 'function') {
      console.error(`Invalid action for type: ${actionType}`);
      return;
    }

    // Handle each action type separately
    switch (actionType) {
      case 'delete':
        const id = typeof item === 'object' ? item.id : item;
        if (window.confirm(I18n.t('admin.messages.deleteConfirm'))) {
          action(id);
        }
        break;

      case 'save':
      case 'edit':
      case 'duplicate':
      case 'details':
      case 'cancel':
        action(item);
        break;

      default:
        console.error('Unknown action type:', actionType);
        break;
    }
  };

  if (isEditing) {
    return (
      <td className="table-actions">
        <button 
          className="save" 
          onClick={(e) => handleClick(onSave, e)}
          disabled={isLoading}
        >
          {loadingAction === 'saving' ? I18n.t('admin.buttons.saving') : I18n.t('admin.buttons.save')}
        </button>
        <button 
          className="cancel" 
          onClick={(e) => handleClick(onCancel, e)}
          disabled={isLoading}
        >
          {I18n.t('admin.buttons.cancel')}
        </button>
      </td>
    );
  }

  return (
    <td className="table-actions">
      {buttons.includes('details') && (
        <button 
          className="details" 
          onClick={(e) => handleClick(onDetails, e)}
          disabled={isLoading}
          title={I18n.t('admin.buttons.details')}
        >
          <i className="fas fa-info-circle" />
        </button>
      )}
      {buttons.includes('edit') && (
        <button 
          className="edit" 
          onClick={(e) => handleClick(onEdit, e)}
          disabled={isLoading}
        >
          {I18n.t('admin.buttons.edit')}
        </button>
      )}
      {buttons.includes('duplicate') && (
        <button 
          className="duplicate" 
          onClick={(e) => handleClick(onDuplicate, e)}
          disabled={isLoading || !onDuplicate}
        >
          {loadingAction === 'duplicating' ? I18n.t('admin.buttons.duplicating') : I18n.t('admin.buttons.duplicate')}
        </button>
      )}
      {buttons.includes('delete') && (
        <button 
          className="delete" 
          onClick={(e) => handleClick(onDelete, e)}
          disabled={isLoading}
        >
          {loadingAction === 'deleting' ? I18n.t('admin.buttons.deleting') : I18n.t('admin.buttons.delete')}
        </button>
      )}
    </td>
  );
};

export default TableActions; 